export default class AppError {
    static TYPE = {
        ['NO_INTERNET']: 'NO_INTERNET',
        ['GLOBAL_UNKNOWN']: 'GLOBAL_UNKNOWN',
        ['SUB_RESPONSE']: 'SUB_RESPONSE'
    };

    constructor(type, data){
        // this.id = Date.now()+""+tools.randomMinMax(10, 99);
        // this.isHandled = false;
        this.type = type;
        this.data = data;
    }
}