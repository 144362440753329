import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app/App.jsx";

function ready(cb) {
    if (document.readyState !== "loading") {
        cb();

        return;
    }

    document.addEventListener("DOMContentLoaded", cb);
}

window.addEventListener("error", (event) => {
    if (event.target && (event.target.tagName === "LINK" || event.target.tagName === "SCRIPT")) {
        const errorDetails = {
            category: "Network Error",
            action: "Resource Load Failure",
            name: event.target.tagName.toLowerCase(),
            value: event.target.href || event.target.src,
            message: `Failed to load ${event.target.tagName.toLowerCase()} at ${event.target.href || event.target.src}`
        };

        window._paq.push(['trackEvent', errorDetails.category, errorDetails.action, errorDetails.name, errorDetails.value]);
        console.error("Network error detected:", errorDetails);
    }
}, true);

window.addEventListener("error", (event) => {
    if (event.target.tagName !== "LINK" && event.target.tagName !== "SCRIPT") {
        window._paq.push(['trackEvent', 'JavaScript Error', event.message, event.filename, event.lineno]);
    }
}, true);

window.addEventListener("unhandledrejection", (event) => {
    window._paq.push(['trackEvent', 'Unhandled Promise Rejection', event.reason, window.location.href]);
});



ready(() => {
    const rootEl = document.getElementById("root");
    rootEl.innerHTML = "";
    const root = createRoot(rootEl);
    root.render(<App />);
});
