import * as ACTION from "../actionTypes";
import localStorage from "../../services/LocalStorage";

export const USER_TOKEN_KEY = 'btoken';

function getInitialState (){
    return {
        access_token: localStorage.getItem(USER_TOKEN_KEY) || null,
        name_first: '',
        name_last: '',
        balance: '',
        avatar: '',
        email: '',
        productList: null,
        addressList: null,
        seedList: null,
        replenish: null
    }
}

export default function user(state = getInitialState(), action) {
    let newState = {...state};

    switch (action.type) {
        case ACTION.USER_SET_DATA:
            newState = {...newState, ...action.data};

            if ( newState.access_token ) {
                localStorage.setItem(USER_TOKEN_KEY, newState.access_token);
            }

            return newState;

        case ACTION.USER_LOGOUT:
            newState = {};

            if (localStorage.hasOwnProperty(USER_TOKEN_KEY) ) {
                localStorage.removeItem(USER_TOKEN_KEY);
                // window.location.reload();
            }

            return getInitialState();

        case ACTION.USER_PRODUCT_LIST_SET:
            newState.productList = Array.isArray(action.list) ? action.list : [];

            return newState;

        case ACTION.USER_PRODUCT_LIST_ADD:
            newState.productList = Array.isArray(action.list) ? [...newState.productList, ...action.list] : [];

            return newState;

        case ACTION.USER_PRODUCT_SELL:
            newState.productList = newState.productList ? [...newState.productList] : [];

            for(let i = 0; i < newState.productList.length; i++){
                if(action.user_product_id === newState.productList[i].user_product_id){
                    newState.productList[i] = {...newState.productList[i]};
                    newState.productList[i].type = 'sold';
                }
            }

            return newState;

        case ACTION.USER_PRODUCT_DELIVERY:
            newState.productList = newState.productList ? [...newState.productList] : [];

            for(let i = 0; i < newState.productList.length; i++){
                if(action.user_product_id === newState.productList[i].user_product_id){
                    newState.productList[i] = {...newState.productList[i]};
                    newState.productList[i].type = 'delivering';
                }
            }

            return newState;

        case ACTION.USER_ADDRESS_LIST_SET:
            newState.addressList = action.list;

            return newState;

        case ACTION.USER_ADDRESS_INFO_SET:
            newState.addressInfo = action.info;

            return newState;

        case ACTION.USER_ADDRESS_ADD:
            newState.addressList = [...newState.addressList, action.address];

            return newState;

        case ACTION.USER_ADDRESS_REMOVE:
            newState.addressList = [...newState.addressList.filter(address => address.id !== action.id)];

            return newState;

        case ACTION.USER_ADDRESS_UPDATE:
            newState.addressList = [...newState.addressList.map(address => {
                // if(address.id === action.address.id){
                //     address = action.address;
                // }

                return address;
            })];

            return newState;

        case ACTION.USER_TRANSACTIONS_SET:
            newState.transactions = action.list;

            return newState;

        case ACTION.USER_TRANSACTIONS_ADD:
            newState.transactions = [...newState.transactions, ...action.list];

            return newState;

        case ACTION.USER_SEED_LIST_SET:
            newState.seedList = action.list;

            return newState;

        case ACTION.USER_REPLENISH_SET:
            newState.replenish = action.replenish;

            return newState;

        default:
            return state;
    }
}