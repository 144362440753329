import React from "react";
import LinkItem from "./LinkItem.jsx";
import {Link} from "wouter-preact";
import logo from "../../svg/new/_logo_white.svg";
import visa from "../../svg/new/_visa.svg";
import mastercard from "../../svg/new/_mastercard.svg";
import opay from "../../svg/new/_opay.svg";
import verve from "../../svg/new/_verve.svg";
import nib from "../../svg/new/_nib.svg";
import socialLinks from './social.js'
import i18n from "../../../i18n/i18n.js";

import "./footer.css";

const FooterView = props => {
    return (
        <footer>
            <div className='footer-content-container'>
                <div className='footer-content'>
                    <div className="footer-content-links-container">
                        <Link to='/' className='logo' aria-label="Main page">
                            <img src={logo} alt=''/>
                        </Link>
                        <div className='footer-content-support'>
                            <div className='footer-content-support-title'>
                                {i18n.get("text.support.number")}
                            </div>
                            <div className='footer-content-support-number'>
                                +234 913 537 0349
                            </div>
                        </div>
                        <div className='footer-content-copyright'>
                            Copyright © 2022-2024. 7UCKY LTD
                        </div>
                    </div>
                    <div className='footer-content-links-container'>
                        <div className='footer-content-link-title'>
                            {i18n.get("footer.title.navigation")}
                        </div>
                        <div className='footer-content-links footer-content-custom-links'>
                            {props.mainLinks.map((link, index) => {
                                return (
                                    <LinkItem
                                        key={index}
                                        url={link.url}
                                        text={link.text}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className='footer-content-links-container'>
                        <div className='footer-content-link-title'>
                            {i18n.get("text.information.for.user.1")}
                        </div>
                        <div className='footer-content-links'>
                            {props.informationLinks.map((link, index) => {
                                return (
                                    <LinkItem
                                        key={index}
                                        url={link.url}
                                        text={link.text}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className='footer-content-payment-container'>
                        <div className='footer-content-link-title'>
                            {i18n.get("text.follow.us")}
                        </div>
                        <div className='footer-content-socials'>
                            {socialLinks.map(({ icon, link }, index) => (
                                <a key={link} target="_blank" href={link} rel="noreferrer">
                                    <div className='footer-content-social' key={index}>
                                        <img src={icon} alt="Logo"/>
                                    </div>
                                </a>
                            ))}
                        </div>
                        <div className='footer-content-link-title'>
                            {i18n.get("footer.title.we.accept")}
                        </div>
                        <div className='footer-content-payments'>
                            {[visa, mastercard, opay, verve, nib].map((x, index) => (
                                <div className='footer-content-payment' key={index}>
                                    <img src={x} alt="Logo"/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterView;
