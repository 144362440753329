import React, {Component} from 'react';
import {Link} from "wouter-preact";

import ProtectedComponent from "../protected/ProtectedComponent.jsx";
import MainButton from "../button/mainButton/MainButton.jsx";

import './userHeaderDesktop.css';

import online from '../../svg/new/_header_online.svg';
import usersIcon from '../../svg/new/_header_users.svg';
import usersWon from '../../svg/new/_header_won.svg';
import usersBox from '../../svg/new/_header_box.svg';

import iconPlus from '../../img/icons/plus.svg';
import iconLogin from '../../img/icons/login_color.svg';
import iconLogout from '../../img/icons/logout.svg';

import UserHeaderInfo from './UserHeaderInfo.jsx';
import {CURRENCY_SIGN} from '../../../../app/admin/constants.js';
import Logo from '../logo/logo.jsx';
import { handleOpenBox } from '../../app/UIController.jsx';
import { withRouter } from '../../../hooks/with-router.jsx';
import Eye from './eye.jsx';
import i18n from '../../../i18n/i18n.js';

class UserHeaderView extends Component {
    getLoginSignupComponent() {
        return (
            <div className='user-actions'>
                <Link to='?signup'>
                    <span className="icon">
                        <img src={iconLogin}/>
                    </span>
                    <span className="text">
                        {i18n.get('button.text.loginSignup')}
                    </span>
                </Link>
            </div>
        );
    }

    getUserPanelComponent() {
        let {user} = this.props;
        let buttonText = <>
            <img src={iconPlus} width="18px" alt="" style={{marginRight: '10px'}}/>
            <span>{i18n.get('button.text.replenish')}</span>
        </>;
        let styleButtonText = {display: 'flex', padding: '0 1vw'};

        if (!user) {
            user = {
                avatar: '',
                name_first: '',
                name_last: '',
                balance: ''
            }
        }

        const returnUrl = location.href;
        const topUpUrl = `?payment=coin&returnUrl=${encodeURIComponent(returnUrl)}`

        return (
            <div className="user-panel">
                <div className="header-balance-container">
                    <div className="header-balance">{i18n.get('text.my.balance')}</div>
                    <div className="header-balance-value">{CURRENCY_SIGN}{user.balance}</div>
                </div>
                <div className="user-panel-btn-add-funds">
                    <Link to={topUpUrl} className="btn-blue" style={{height: "36px"}}>
                        <MainButton text={buttonText} styleText={styleButtonText}/>
                    </Link>
                </div>

                <div className="user-panel-info">
                    <Link to="/inventory/got/" className="user-panel-info-link">
                        <div className="user-panel-info-left">
                            <img className="user-panel-info-avatar" src={user.avatar} alt=""/>
                        </div>
                        <div className="user-panel-info-right">
                            <div className="user-panel-info-right-balance">
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="user-panel-btn-logout" onClick={() => this.props.onLogout()}>
                    <img src={iconLogout} alt=""/>
                </div>
            </div>
        );
    }

    openBoxHandler = () => {
        handleOpenBox(this.box, this.props.history, true);
    };

    render() {
        let pathname = window.location.pathname;
        let isBoxPage = pathname.startsWith('/box');
        let isProductPage = pathname.startsWith('/product');
        let boxNames = ['computers', 'phones-and-tablets', 'sound', 'games-and-tv-', 'home-appliances', 'vehicles-', 'travel-vacations', 'mens-clothes', 'womens-clothes', 'mens-accessories', 'womens-accessories', 'surprise-box']
        let foundIndex = 0;

        const eyeLottieOptions = {
            loop: true,
            autoplay: true,
            animationData: null, //lottieEye,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        
        for (let i = 0; i < boxNames.length; i++) {
            if (pathname.includes(boxNames[i])) {
                foundIndex = i;
                break; // Stop the loop once a match is found
            }
        }

        const { box, history } = this.props;

        return (
            <>
                <div className="header">
                    <div className="header-left">
                        <Link to="/" className="logo" aria-label="Main page">
                            <Logo />
                        </Link>
                        <div className="users-online">
                            <img src={online} alt="Online"/>
                            <p className="num">{this.props.stats.online}</p>
                            <p className="text">{i18n.get('text.stats.online')}</p>
                        </div>
                    </div>
                    <div className="header-right">
                        <Link to="/#main-win-stats-block">
                            <div className="header-stats-items">
                                <UserHeaderInfo
                                    value={`₦${this.props.stats?.wins || 0}`}
                                    caption={i18n.get('header.worthOfPrizesWon')}
                                    icon={<img src={usersWon} alt="Icon" width="32px" height="30px"/>}
                                />
                                <UserHeaderInfo
                                    value={this.props.stats?.unpacked || 0}
                                    caption={i18n.get('header.boxesOpened')}
                                    icon={<img src={usersBox} alt="Icon" width="32px" height="32px" />}
                                />
                                <UserHeaderInfo
                                    value={this.props.stats?.users || 0}
                                    caption={i18n.get('header.usersWithUs')}
                                    icon={<img src={usersIcon} alt="Icon" width="28px" height="28px" />}
                                />
                            </div>
                        </Link>
                        <ProtectedComponent nonAuthComponent={this.getLoginSignupComponent()}>
                            {this.getUserPanelComponent()}
                        </ProtectedComponent>
                    </div>
                </div>
                {(isBoxPage || isProductPage) ? (
                    <div className={`header header-with-views ${this.props.isActiveHeader ? 'active' : ''}`}>
                        <div className="header-left">
                            <Link to="/" className="logo">
                                <Logo />
                            </Link>
                        </div>
                        <div className="header-center">
                            <MainButton text={'Open box'} onClickHandler={() => handleOpenBox(box, history, true)} />
                        </div>
                        <div className="header-right">
                            <UserHeaderInfo
                                value={this.props.stats.visitorsPerBox ? this.props.stats.visitorsPerBox[foundIndex] : 0}
                                caption={i18n.get('header.looking')}
                                icon={<Eye/>}
                            />
                        </div>
                    </div>
                ) : null}
            </>
        )
    }
}

export default withRouter(UserHeaderView);
