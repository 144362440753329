import { useMemo } from "react";
import { useLocation as _useLocation, useSearch } from "wouter-preact";

export const useHistory = () => {
    const [location, navigate] = _useLocation();
    

    const history = useMemo(() => {
        return {
            push: (path) => typeof path === 'object' ? navigate(location + '?' + path.search) : navigate(path),
            replace: (path) => navigate(path, { replace: true }),
        };
    }, [location, navigate]);

    return history;
};

export const useLocation = () => {
    const [location] = _useLocation();
    const search = useSearch();

    return useMemo(() => {
        return {
            pathname: location,
            search,
        };
    }, [location, search])
}
