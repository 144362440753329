class LoaderError {
    constructor (text){
        return new Error('Loader.fetch() - '+text);
    }
}

export default class Loader {
    static ERROR_TYPE = {
        NETWORK: 'network'
    };

    static domain = DOMAIN || '';

    static checkInputArguments (args){
        if(!args.url) return new LoaderError('no url!');
    }

    static fetch (params){
        return new Promise(((resolve, reject) => {
            const error = Loader.checkInputArguments(params);

            let {url, ...rest} = params;

            rest['redirect'] = 'follow';

            if(error) reject(error);

            fetch(Loader.domain+url, rest).then(response => {
                resolve(response);
            }).catch(error => {
                reject({status: 'error', errorType: Loader.ERROR_TYPE.NETWORK});
            });
        }));
    }
}