import React from "react";
import Lottie from "../lottie";

const eyeLottieOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const Eye = () => {
    return (
        <Lottie
            loadAnimation={() => import("../../lotties/eye.json")}
            options={eyeLottieOptions}
        />
    );
};

export default React.memo(Eye);
