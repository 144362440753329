let count = 0;
let scrollY = 0;

export const disableBodyScroll = () => {
    count++;

    if (!document.body.classList.contains('disable-body-scroll')) {
        scrollY = window.scrollY;
        document.body.style.top = `-${scrollY}px`;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.overflowY = 'scroll';
        document.body.classList.add('disable-body-scroll');
    }

    return () => {
        if (--count === 0) {
            document.body.classList.remove('disable-body-scroll');
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = '';
            document.body.style.overflowY = '';
            window.scrollTo(0, scrollY);
            scrollY = 0;
        }
    };
}



