import React from "react";
import store from "../../storage/store.js";
import AppError from "../../errors/AppError.js";
import * as dispatches from "../../storage/dispatches.js";

const errorMap = {
	401(code) {
		store.dispatch(dispatches.userLogout());
	},
	403(code) {
		store.dispatch(dispatches.userLogout());
	}
};

const errorTextMap = {
	'401': false,
	'403': false,
	'500': 'server.error.code.five.handred',
	'501': 'error.something.went.wrong',
	'502': 'error.something.went.wrong',
	'503': 'error.something.went.wrong',
	'1004': 'error.invalid.agreement',
	'1005': 'error.invalid.role',
	'1011': 'error.invalid.email',
	'1012': 'error.invalid.first.name',
	'1013': 'error.invalid.last.name',
	'1014': 'error.invalid.birthday',
	'1015': 'error.invalid.avatar.id',
	'1016': 'error.invalid.avatar.file',
	'1018': 'error.invalid.country',
	'1019': 'error.invalid.region',
	'1020': 'error.invalid.city',
	'1021': 'error.invalid.address',
	'1022': 'error.invalid.zip',
	'1023': 'error.invalid.phone',
	'1025': 'error.invalid.gender',
	'1032': 'error.invalid.amount',
	'1051': 'error.not.enough.money',
	'1061': 'error.too.many.addresses'
};

function createErrorNotification(code) {
	if(code === 401 || code === 403){
		return null;
	}

	let notification = {type: 'error', translateKeys: {title: 'text.error', description: ''}};

	if(errorTextMap[code]){
	    notification.translateKeys.description = errorTextMap[code];
    }
	else{
        notification.translateKeys.description = null;
        notification.errorCode = code;
    }

	return notification
}

function errorHandler(response) {
	const {code} = response;

	if (errorMap[code]) errorMap[code](code);

	store.dispatch(dispatches.dispatchError(AppError.TYPE.SUB_RESPONSE, {code}));
	store.dispatch(dispatches.addNotification(createErrorNotification(code)));
}

export {errorHandler};