import React, { Suspense, useEffect } from "react";
import './basePopup.css';

import closePopupIcon from '../../svg/new/_close.svg';
import closePopupNewIcon from '../../svg/new/_close_new.svg';
import Preloader from "../../components/preloader/Preloader.jsx";
import { disableBodyScroll } from "../../../scroll.js";

const BasePopup = ({ id, closeIcon, isNewClose, onClickBackground, children }) => {
    useEffect(() => {
        return disableBodyScroll();
    }, []);

    const onMouseDownHandler = (e) => {
        e.stopPropagation();
    };

    const resolvedCloseIcon = closeIcon || (isNewClose ? closePopupNewIcon : closePopupIcon);

    return (
        <div id={id} className='base-popup'>
            <div className="popup-overlay" onClick={onClickBackground} />
            <div className="popup-wrapper">
                <div className="base-popup-content">
                    <div className="base-popup-children" onMouseDown={onMouseDownHandler}>
                        <div className="base-popup-close" onClick={onClickBackground}>
                            <img src={resolvedCloseIcon} alt="Close" />
                        </div>
                        <Suspense fallback={<div className="preloader-wrap-popup"><Preloader /></div>}>
                            {children}
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasePopup;
