import React from "react";
import preloaderIcon from '../../svg/preloader.svg';
import './preloader.css';

const Preloader = () => {
    return (
        <div className="preloader">
            <img src={preloaderIcon} alt="Preloader"/>
        </div>
    );
};

export default Preloader;