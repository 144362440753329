import * as actionTypes from "../actionTypes";

let initialState = {
    list: [],
    list_free: [],
    current: null
};

export default function prize(state = initialState, action) {
    let newState = {...state};

    switch (action.type) {
        // case actionTypes.PRIZE_CURRENT_SET:
        //     newState.current = action.currentPrize;
        //
        //     newState.list.push(action.currentPrize);
        //
        //     return newState;
        // case actionTypes.PRIZE_LIST_SET:
        //     newState.list = action.list;
        //
        //     return newState;
        case actionTypes.PRIZE_LIST_ADD_ITEM:
            newState.list.push(action.prize);

            return newState;
        case actionTypes.PRIZE_LIST_MARK_SOLD_ITEM:
            const user_product_id = action.id;

            for(let i = 0; i < newState.list.length; i++){
                if(newState.list[i].user_product_id === user_product_id){
                    newState.list[i].isSold = true;
                }
            }

            return newState;
        case actionTypes.PRIZE_LIST_FREE_ADD_ITEM:
            newState.list_free.push(action.prize);

            return newState;
        default:
            return state
    }
}