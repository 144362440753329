import React from "react";
import BasePopup from "../_basePopup/BasePopup.jsx";
import Background from '../../img/popups/logIn/logInBg.svg'
import './info.css'

class Info extends React.Component {
    render() {
        return (
            <BasePopup onClickBackground={this.props.onBackgroundClick} popupWidth={867}>
                <div className="login-popup-content" style={{backgroundImage: `url(${Background})`}}
                     onClick={e => e.stopPropagation()}>
                    <div style={{width: '450px'}}>{this.props.children}</div>
                </div>
            </BasePopup>
        )
    }
}

export default Info;
