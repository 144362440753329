export const USER = 'user';
export const USER_INFO = 'userInfo';
export const USER_PROFILE = 'userProfile';
export const USER_PROFILE_UPDATE = 'userProfileUpdate';
export const USER_TRANSACTIONS = 'userTransactions';
export const USER_PROFILE_PASSWORD_UPDATE = 'userPasswordUpdate';
export const USER_PRODUCT_LIST = 'userProductList';
export const USER_PRODUCT_SELL = 'userProductSell';
export const USER_ADDRESS_INFO = 'userAddressInfo';
export const USER_ADDRESS_LIST = 'userAddressList';
export const USER_ADDRESS_ADD = 'userAddressAdd';
export const USER_ADDRESS_UPDATE = 'userAddressUpdate';
export const USER_ADDRESS_REMOVE = 'userAddressRemove';
export const USER_PRODUCT_DELIVERY = 'userProductDeliver';
export const USER_SEED_REVEAL = 'userSeedReveal';
export const USER_SEED_LIST = 'userSeedList';
export const USER_REPLENISH = 'userReplenish';

export const BOX_ALL = 'boxAll';
export const BOX_OPEN = 'boxOpen';
export const BOX_INFO = 'boxInfo';
export const BOX_PRODUCTS = 'boxProducts';
export const BOX_RECOMMENDED = 'boxRecommended';
export const BOX_WITH_PRODUCT = 'boxWithProduct';

export const CATEGORY_BOX_LIST = 'categoryBoxList';
export const CATEGORY_BOX_INFO = 'categoryBoxInfo';
export const CATEGORY_BOX_BOXES = 'categoryBoxBoxes';

export const PRODUCT_INFO = 'productInfo';
export const PRODUCT_RECOMMENDED = 'productRecommended';

export const STATS = 'stats';

export const LAST_WINNERS = 'lastWinners';

export const LANGUAGE_LIST = 'languageList';
export const LANGUAGE_KEY_LIST = 'languageKeyList';

export const PHONE_LIST = 'phoneList';

export const ADDRESS_LIST = 'addressList';
export const ADDRESS_CHILDREN = 'addressChildren';
export const COUNTRY_LIST = 'countryList';


