import React from "react";
import {connect} from "react-redux";
import AppError from "../../../errors/AppError.js";

import {PopupTitle} from "../../popups/popupTitle/PopupTitle.jsx";
import i18n from "../../../i18n/i18n";
import * as dispatches from "../../../storage/dispatches";

const errorMap = {
    '401': false,
    '403': false,
    '500': 'server.error.code.five.handred',
    '501': 'error.something.went.wrong',
    '502': 'error.something.went.wrong',
    '503': 'error.something.went.wrong',
    '1004': 'error.invalid.agreement',
    '1005': 'error.invalid.role',
    '1011': 'error.invalid.email',
    '1012': 'error.invalid.first.name',
    '1013': 'error.invalid.last.name',
    '1014': 'error.invalid.birthday',
    '1015': 'error.invalid.avatar.id',
    '1016': 'error.invalid.avatar.file',
    // '1017': 'Error! invalid password',
    '1018': 'error.invalid.country',
    '1019': 'error.invalid.region',
    '1020': 'error.invalid.city',
    '1021': 'error.invalid.address',
    '1022': 'error.invalid.zip',
    '1023': 'error.invalid.phone',
    '1025': 'error.invalid.gender',
    '1032': 'error.invalid.amount',
    '1051': 'error.not.enough.money',
    '1061': 'error.too.many.addresses'
};

class ErrorViewer extends React.Component{
    constructor(props){
        super(props);

        this.initialState = {
            currentError: null
        };

        this.state = this.initialState;
        this.handleError();
    }

    componentDidUpdate(prevProps){
        if(prevProps.currentError !== this.props.currentError){
            this.handleError();
        }
    }

    handleError = () => {
        const error = this.props.currentError;
        let content = null;

        if(error){
            switch (error.type){
                case AppError.TYPE.NO_INTERNET:
                    content = (
                        <PopupTitle title={i18n.get('text.error')} onClickBackground={this.clearError}>
                            <div>
                                {i18n.get('error.internet.connection')}
                                <br /><br />
                                {i18n.get('error.check.internet.connection')}
                            </div>
                        </PopupTitle>
                    );
                    this.props.addAlert({type: 'error', title: i18n.get('text.error'), description: i18n.get('error.internet.connection') +" "+ i18n.get('error.check.internet.connection')});
                    break;
                case AppError.TYPE.GLOBAL_UNKNOWN:
                    content = (
                        <PopupTitle title={i18n.get('text.error')} onClickBackground={this.clearError}>
                            <div>{i18n.get('global.unhandled.code')} {error.data.code}</div>
                        </PopupTitle>
                    );
                    this.props.addAlert({type: 'error', title: i18n.get('text.error'), description: i18n.get('global.unhandled.code') +""+ error.data.code});
                    break;
                case AppError.TYPE.SUB_RESPONSE:
                    let errorDescription = i18n.get(errorMap[error.data.code]);

                    if(errorDescription){
                        content = (
                            <PopupTitle title={i18n.get('text.error')} onClickBackground={this.clearError}>
                                <div>{errorDescription}</div>
                            </PopupTitle>
                        );
                        this.props.addAlert({type: 'error', title: i18n.get('text.error'), description: errorDescription});
                    }
                    else if(errorDescription === undefined){
                    }

                    break;
                default:
                    return null;
            }

            this.setState({currentError: content});
        }
    };

    clearError = () => {
        this.setState(this.initialState);
    };

    render(){
        return this.state.currentError;
    }
}

function mapStateToProps (state){
    return {
        ...state.error,
    };
}

function mapDispatchToProps (dispatch){
    return {
        addAlert: data => dispatch(dispatches.addNotification(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorViewer);