import React, { useEffect, Suspense } from "react";
import DocumentMeta from "react-document-meta";

import Banner from "../../components/banner/Banner.jsx";
import HowItWorks from "../../components/howItWorks/HowItWorks.jsx";
import BoxList from "../../components/boxList/BoxList.jsx";
import Reviews from "../../components/reviews/Reviews.jsx";
import AboutUs from "../../components/aboutus/AboutUs.jsx";
import Map from "../../components/map/Map.jsx";

import partnersLogoJumia from "../../img/partners/partners-jumia-logo.svg";
import partnersLogoShoprite from "../../img/partners/partners-shoprite-logo.svg";
import partnersLogoJiji from "../../img/partners/partners-jiji-logo.webp";
import partnersLogoKonga from "../../img/partners/partners-konga-logo.svg";
import partnersLogoTranssion from "../../img/partners/partners-transsion-logo.webp";

import './main.css';
import './partners.css';
import LazyLoadedComponent from "../../components/lazyLoad/lazyLoad.jsx";
import Preloader from "../../components/preloader/Preloader.jsx";
import * as UIController from "../../app/UIController.jsx";
import { useParams } from "wouter-preact";
import MainButton from "../../components/button/mainButton/MainButton.jsx";
import { useHistory } from "../../../hooks/router.js";
import i18n from "../../../i18n/i18n.js";
import * as dispatches from "../../../storage/dispatches.js";
import { useDispatch } from "react-redux";
const meta = {
    title: `Unique Gift Boxes for Every Occasion | 7uckyBox`,
    description: `Discover the perfect gift with 7uckyBox's diverse range of themed gift boxes. 🛍️🎁  Ideal for birthdays, anniversaries, and special celebrations. 🎉🎈 Shop now!`,
};

const load = () => import("../../components/winStats/WinStats.jsx");
const ResetPassword = React.lazy(() => import("../../popups/resetPassword/ResetPassword.jsx"));

const Main = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const email_token = params.email_token;

        if (email_token) {
            UIController.popupInfo.show({
                title: "Welcome to Boxydo!",
                content: (
                    <div style={{ height: "40px", margin: "20px 0" }}>
                        <MainButton
                            text={i18n.get("button.text.login")}
                            link="./?login"
                        />
                    </div>
                ),
            });
    
            history.replace("/");
        }
    }, [params.email_token]);

    useEffect(() => {
        const reset_token = params.reset_token;
        if (!reset_token) return;

        let resultHandler = (result) => {
            let title = "";
            let content = "";

            if (result) {
                title = "Thanks";

                content = (
                    <div>
                        <div style={{ marginBottom: "20px" }}>
                            New password saved.
                            <br />
                            You can login using your new password.
                        </div>
                        <div style={{ height: "40px", marginBottom: "20px" }}>
                            <MainButton
                                text={i18n.get("button.text.login")}
                                link="/?login"
                            />
                        </div>
                    </div>
                );
            } else {
                title = "Error!";
                content = "Password reset error! Try again later.";
            }

            history.replace("/");
            UIController.popupInfo.show({ title, content });
        };

        

        dispatch(
            dispatches.popupShow(
                <Suspense>
                    <ResetPassword
                        token={reset_token}
                        onResult={resultHandler}
                    />
                </Suspense>
            )
        );
    }, [params.reset_token]);

    return (
        <>
            <DocumentMeta {...meta} />
            <div className="user-main-page-content">
                <Banner />
                <BoxList />
            </div>
            <HowItWorks />
            <Reviews />
            <div className="win-stats-content-wrap" id="main-win-stats-block">
                <LazyLoadedComponent 
                    load={load}
                    loading={<Preloader/>}>
                    {(WinStats) => <WinStats/>}
                </LazyLoadedComponent>
            </div>
            <>
                <div className="partners-content-wrap">
                    <div className="partners-logos-wrap">
                        <h2>Our partners</h2>
                        <div className="items-wrap">
                            <div className="item">
                                <img loading="lazy" src={partnersLogoJumia} alt="Jumia logo" width="188" height="32"/>
                            </div>
                            <div className="item">
                                <img loading="lazy" src={partnersLogoShoprite} alt="Shoprite logo" width="96" height="106" />
                            </div>
                            <div className="item">
                                <img loading="lazy" src={partnersLogoJiji} alt="Jiji logo" width="124" height="115"/>
                            </div>
                            <div className="item">
                                <img loading="lazy" src={partnersLogoKonga} alt="Konga logo" width="184" height="50" />
                            </div>
                            <div className="item">
                                <img loading="lazy" src={partnersLogoTranssion} alt="Transsion logo" width="205" height="56"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <AboutUs />
            <Map />
        </>
    );
};

export default React.memo(Main);
