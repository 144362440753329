import React, { useEffect, useMemo } from "react";
import { useParams } from "wouter-preact";
import { useSelector } from "react-redux";

import QueryController from "../../../services/QueryController.js";

import "../boxList/boxList.css";
import BoxItem from "../boxItem/BoxItem.jsx";

const BOXES_PLACEHOLDER = new Array(12).fill({ loading: true });

const BoxList = (props) => {
    const { name_id } = useParams();

    const boxListProp = props.boxList;
    const boxList = useSelector((state) => state.box.boxList);
    const isMobile = useSelector((state) => state.app.isMobile);

    const pathname = window.location.pathname;
    const isProductPage = pathname.startsWith("/product");

    useEffect(() => {
        const TYPES = QueryController.TYPES;
        const query = name_id
            ? {
                  type: TYPES.CATEGORY_BOX_BOXES,
                  params: { name_id },
              }
            : { type: TYPES.BOX_ALL };

        QueryController.registerQuery(query);
        QueryController.exec();
    }, [name_id]);

    const listToRender = useMemo(() => {
        const listToRender = (boxListProp || boxList).length
            ? (boxListProp || boxList)
            : isMobile
            ? []
            : BOXES_PLACEHOLDER;

        return listToRender.map((box, index) => {
            return <BoxItem box={box} key={box.id || index} />;
        });
    }, [isMobile, boxList, boxListProp]);

    return (
        <div
            className={`box-list ${isProductPage ? "product-box-list" : null}`}
            id="main-boxes-block">
            <div className="box-list-items">{listToRender}</div>
        </div>
    );
};

export default React.memo(BoxList);
