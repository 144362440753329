import React from "react";
import { Link } from "wouter-preact";
import MainButton from "../button/mainButton/MainButton.jsx";
import "./map.css";
import LazyLoadedComponent from "../lazyLoad/lazyLoad.jsx";
import { useSelector } from "react-redux";

const Map = () => {
    const isMobile = useSelector(state => state.app.isMobile);

    return (
        <div className="map-content-wrap" id="main-contact-us-block">
            <div className="map-wrap">
                {<LazyLoadedComponent load={() => import("./MapComponent.jsx")}>
                    {(MapComponent) => 
                        isMobile ? (
                            <MapComponent
                                defaultCenter={{
                                    lat: 6.5576669619373975,
                                    lng: 3.280561448340024,
                                }}
                                defaultZoom={11}
                            />
                        ) : (
                            <MapComponent
                                defaultCenter={{
                                    lat: 6.570518935918695,
                                    lng: 3.214233454159932,
                                }}
                            />
                        )
                    }
                </LazyLoadedComponent>}
            </div>
            <div className="card-wrap">
                <h2>Contact us</h2>
                <div className="links-wrap">
                    <div className="item">
                        <p>Main Office:</p>
                        <a href="mailto:careteam@7uckybox.com">
                            careteam@7uckybox.com
                        </a>
                    </div>
                    <div className="item">
                        <p>Delivery Center Warehouse:</p>
                        <a href="mailto:warehouse@7uckybox.com">
                            warehouse@7uckybox.com
                        </a>
                    </div>
                </div>
                <div className="btn-wrap">
                    <Link to="/box/surprise-box/">
                        <MainButton text={"Get Started"} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Map;
