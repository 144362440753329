import React, { useCallback } from "react";
import BlazeSlider from "blaze-slider";
import "blaze-slider/dist/blaze.css";

export function useBlazeSlider(config) {
    const sliderRef = React.useRef();
    const elRef = React.useRef();

    React.useEffect(() => {
        if (sliderRef.current) {
            // Re initialize slider in config change
            sliderRef.current.destroy();
        }
        sliderRef.current = new BlazeSlider(elRef.current, config);
    }, [config]);

    return [elRef, sliderRef];
}

export function Slider({ config, children, footer, arrows = false }) {
    const [elRef, sliderRef] = useBlazeSlider(config);

    const prev = useCallback(() => sliderRef.current.prev(), [sliderRef]);
    const next = useCallback(() => sliderRef.current.next(), [sliderRef]);

    return (
        <div className="blaze-slider" ref={elRef}>
            <div className="blaze-container">
                <div className="blaze-track-container">
                    {arrows && <div onClick={prev} className="arrow-left"></div>}
                    <div className="blaze-track">{children}</div>
                    {arrows && <div onClick={next} className="arrow-right"></div>}
                </div>
            </div>
            {footer}
        </div>
    );
}
