import * as actionTypes from "../actionTypes";

let initialState = {
    boxList: [],
    boxRecommendedList: [],
    boxItem: null,
    boxItemProductList: [],
    boxToOpen: null,
};

export default function box(state = initialState, action) {
    // console.log('[BOX.REDUCER.ACTION]:', action);

    let newState = {...state};

    switch (action.type) {
        case actionTypes.BOX_LIST_SET:
            newState.boxList = action.boxes;

            return newState;
        case actionTypes.BOX_RECOMMENDED_LIST_SET:
            newState.boxRecommendedList = action.boxes;

            return newState;
        case actionTypes.BOX_ITEM_SET:
            newState.boxItem = action.box;

            return newState;
        case actionTypes.BOX_ITEM_PRODUCTS_SET:
            newState.boxItemProductList = action.products;

        case actionTypes.BOX_TO_OPEN_SET:
            newState.boxToOpen = action.box;

            return newState;
        default:
            return state
    }
}