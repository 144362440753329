import React from "react";
import { Link } from "wouter-preact";

import MainButton from "../button/mainButton/MainButton.jsx";
import i18n from "../../../i18n/i18n";

import "./boxItem.css";

import saleTagImg from "../../svg/sale-tag.svg";
import { CURRENCY_SIGN } from "../../../../app/admin/constants.js";
import { handleOpenBox } from "../../app/UIController.jsx";
import { useHistory } from "../../../hooks/router.js";

const A = ({ children, className }) => <div className={className}>{children}</div>;

const BoxItem = ({ box }) => {
    const history = useHistory();

    const isSale = box.isSale;
    const className = isSale ? "box-item-sale" : "box-item";
    const saleTag = isSale ? (
        <img className="box-item-img-sale-tag" src={saleTagImg} alt="" />
    ) : null;
    const productGradient = isSale ? (
        <div className="box-item-img-radial-sale"></div>
    ) : (
        <div className="box-item-img-radial"></div>
    );

    const handleOpenClick = (e, box) => {
        e.preventDefault();
        e.stopPropagation();

        handleOpenBox(box, history, true);
    };

    const Wrap = box.loading ? A : Link;

    return (
        <Wrap className={className} to={"/box/" + box.name_id + "/"} >
            <div className="box-item-img">
                {box.loading ? (
                    <img className="img-normal" />
                ) : (
                    <>
                        <img
                            src={box.image.normal}
                            alt={box.description}
                            className="img-normal"
                            loading="lazy"
                        />
                        <img
                            src={box.image.hover}
                            alt={box.description}
                            className="img-hover"
                            loading="lazy"
                        />
                    </>
                )}

                {saleTag}
                {productGradient}
            </div>
            <div className="box-item-desc">{box.description || '\u00A0'}</div>
            <div className="box-item-price">
                <div className="box-item-price-new">
                    {CURRENCY_SIGN}
                    {box?.price}
                </div>
                <MainButton
                    text={i18n.get("button.box.open")}
                    onClickHandler={(e) => handleOpenClick(e, box)}
                />
            </div>
        </Wrap>
    );
};

export default BoxItem;
