import * as CONST from "./QueryConstants";
import apiHandler from "./handlers/apiHandler";
import ApiLoader from "./ApiLoader";
import tools from "../js/tools";

let map = {
    [CONST.USER_INFO]: function (query, params) {
        query.userInfo(params);
    },
    [CONST.USER_PROFILE]: function (query, params) {
        query.userProfile(params);
    },
    [CONST.USER_TRANSACTIONS]: function (query, params) {
        query.userTransactions(params);
    },
    [CONST.USER_PRODUCT_LIST]: function (query, params) {
        query.userProductList(params);
    },
    [CONST.USER_SEED_REVEAL]: function (query, params) {
        query.userSeedReveal(params);
    },
    [CONST.USER_SEED_LIST]: function (query, params) {
        query.userSeedList(params);
    },
    [CONST.USER_ADDRESS_LIST]: function (query, params) {
        query.userAddressList(params);
    },
    [CONST.USER_ADDRESS_INFO]: function (query, params) {
        query.userAddressInfo(params);
    },
    [CONST.BOX_ALL]: function (query, params) {
        query.allBox(params);
    },
    [CONST.BOX_OPEN]: function (query, params) {
        query.openBox(params);
    },
    [CONST.BOX_INFO]: function (query, params) {
        query.boxInfo(params);
    },
    [CONST.BOX_PRODUCTS]: function (query, params) {
        query.boxProducts(params);
    },
    [CONST.BOX_RECOMMENDED]: function (query, params) {
        query.boxRecommended(params);
    },
    [CONST.BOX_WITH_PRODUCT]: function (query, params) {
        query.boxWithProduct(params);
    },
    [CONST.CATEGORY_BOX_LIST]: function (query, params) {
        query.categoryBoxList(params);
    },
    [CONST.CATEGORY_BOX_INFO]: function (query, params) {
        query.categoryBoxInfo(params);
    },
    [CONST.CATEGORY_BOX_BOXES]: function (query, params) {
        query.categoryBoxBoxes(params);
    },
    [CONST.PRODUCT_INFO]: function (query, params) {
        query.productInfo(params);
    },
    [CONST.PRODUCT_RECOMMENDED]: function (query, params) {
        query.productRecommended(params);
    },
    [CONST.STATS]: function (query, params) {
        query.stats(params);
    },
    [CONST.LAST_WINNERS]: function (query, params) {
        query.lastWinners(params);
    },
    [CONST.LANGUAGE_LIST]: function (query, params) {
        query.languageList(params);
    },
    [CONST.LANGUAGE_KEY_LIST]: function (query, params) {
        query.languageKeyList(params);
    },
    [CONST.PHONE_LIST]: function (query, params) {
        query.phoneList(params);
    },
    [CONST.ADDRESS_LIST]: function (query, params) {
        query.addressList(params);
    },
    [CONST.ADDRESS_CHILDREN]: function (query, params) {
        query.addressChildren(params);
    },
    [CONST.COUNTRY_LIST]: function (query, params) {
        query.countryList(params);
    },
};

class QueryController {
    static TYPES = CONST;
    static currentQuery = null;
    static callbacks = {};
    static intervalSec = 5;
    static intervalStartSec = Math.round(Date.now() / 1000);
    static interval = setInterval(() => {
        for (let key in QueryController.intervalQueries) {
            let intervalKey = Number(key);
            if (isNaN(intervalKey)) continue;

            let currentDate = Math.round(Date.now() / 1000);
            let isTime =
                (currentDate - QueryController.intervalStartSec) %
                    intervalKey ===
                0;
            if (isTime) {
                QueryController.intervalQueries[intervalKey].forEach(
                    (intervalQuery) => {
                        let { interval, ...rest } = intervalQuery;
                        QueryController.registerQuery(rest);
                    }
                );
            }
        }

        QueryController.exec();
    }, QueryController.intervalSec * 1000);

    static intervalQueries = {};

    static registerQuery(queryList) {
        if (!Array.isArray(queryList)) {
            if (typeof queryList === "object") {
                queryList = [queryList];
            } else {
                console.error("Invalid query list:", queryList);
                return;
            }
        }

        if (QueryController.currentQuery == null) {
            try {
                QueryController.currentQuery = ApiLoader.privateQueryFetch();
            } catch (error) {
                console.error("Failed to initialize currentQuery:", error);
                return;
            }
        }

        for (let i = 0; i < queryList.length; i++) {
            let query = queryList[i];
            let handler = map[query.type];

            if (!handler) {
                console.error(
                    "QueryController() - unhandled query - " + query.type
                );
                continue;
            }

            handler(QueryController.currentQuery, query.params || {});

            if (query.callback) {
                QueryController.callbacks[query.type] = query.callback;
            }

            if (query.interval) {
                let roundedInterval = tools.roundTo(
                    QueryController.intervalSec,
                    query.interval
                );

                if (!QueryController.intervalQueries[roundedInterval]) {
                    QueryController.intervalQueries[roundedInterval] = [];
                }

                QueryController.intervalQueries[roundedInterval].push(query);
            }
        }
    }

    static unregisterQueryInterval(queryList) {
        if (!Array.isArray(queryList)) {
            if (typeof queryList === "object") {
                queryList = [queryList];
            } else {
                console.error(
                    "Invalid query list for unregistering:",
                    queryList
                );
                return;
            }
        }

        for (let i = 0; i < queryList.length; i++) {
            let currentQuery = queryList[i];
            let intervalSec = currentQuery.interval;
            let intervalQueryArray =
                QueryController.intervalQueries[intervalSec];

            if (!intervalQueryArray) continue;

            for (let m = intervalQueryArray.length - 1; m >= 0; m--) {
                let intervalQuery = intervalQueryArray[m];

                if (intervalQuery.type === currentQuery.type) {
                    intervalQueryArray.splice(m, 1);
                    break;
                }
            }
        }
    }

    static exec(callback) {
        if (!QueryController.currentQuery) {
            console.warn("No current query to execute");
            return;
        }

        const memorizedCallbacks = { ...QueryController.callbacks };
        const memorizedQuery = QueryController.currentQuery;

        QueryController.callbacks = {};
        QueryController.currentQuery = null;

        memorizedQuery
            .exec()
            .then(apiHandler)
            .then((result) => {
                for (let key in memorizedCallbacks) {
                    let currentCallback = memorizedCallbacks[key];

                    if (typeof currentCallback === "function") {
                        currentCallback(result[key]);
                    }
                }

                if (callback) {
                    callback({ status: "success" });
                }
            })
            .catch((error) => {
                console.error("Error executing memorized query:", error);
                for (let key in memorizedCallbacks) {
                    let currentCallback = memorizedCallbacks[key];

                    if (typeof currentCallback === "function") {
                        currentCallback(error);
                    }
                }

                if (callback) {
                    callback(error);
                }
            });
    }
}

export default QueryController;
