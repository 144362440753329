/**
 * ScrollToHashElement Component
 *
 * This React component automatically scrolls to the element specified in the URL hash when the component is mounted
 * and when the hash changes. It also overrides the default browser behavior for navigating and managing history states
 * to ensure smooth scrolling and proper handling of in-page navigation.
 * 
 * The key functionalities of this component are:
 * 1. Scroll to the element referenced by the URL hash on initial render and when the hash changes.
 * 2. Override the browser's pushState and replaceState methods to trigger custom events, ensuring that the component
 *    responds to history changes (e.g., back/forward navigation).
 * 3. Smoothly scroll to the target element within a specified number of retries if the element is not immediately in the viewport.
 * 4. Automatically remove the hash from the URL after scrolling to the element, preventing the default anchor link behavior.
 */

import { useLayoutEffect, useState, useEffect } from "react";

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const removeHashCharacter = (str) => {
  const result = str.slice(1);
  return result;
};

function navigateToElement(id, retries = 3) {
  return new Promise((resolve) => {
    function attemptScroll(retriesLeft) {
      if (retriesLeft === 0) {
        return resolve(false);
      }
      const element = document.getElementById(id);
      if (element) {
        if (isElementInViewport(element)) {
          resolve(true);
          return;
        }
  
        if (retriesLeft <= 0) {
          resolve(true);
          return;
        }

        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scroll({top: y, behavior: 'smooth'});
        waitForScrollEnd().then(() => attemptScroll(retriesLeft - 1))
      } else {
        delay(500).then(() => attemptScroll(retriesLeft - 1))
      }
    }

    attemptScroll(retries);
  });
}

function waitForScrollEnd() {
  const WAIT_FOR_SCROLL_MS = 30;
  return new Promise((resolve) => {
    let scrollTimeout;

    const onTimeout = () => {
      window.removeEventListener('scroll', onScroll, true);
      resolve();
    };
    const onScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(onTimeout, WAIT_FOR_SCROLL_MS);
    };

    window.addEventListener('scroll', onScroll, true);
    onScroll();
  });
}

const ScrollToHashElement = () => {
  const [hash, setHash] = useState(window.location.hash);

  useEffect(() => {
    let lastHash = document.location.hash;
    let interval = setInterval(() => {
      if (lastHash !== document.location.hash) {
        lastHash = document.location.hash;
        setHash(lastHash);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [setHash]);

  useEffect(() => {
    const handleClick = (e) => {
      const target = e.target.closest('a');
      if (!target) {
        return;
      }

      if (
        e.ctrlKey || 
        e.shiftKey || 
        e.metaKey || // apple
        (e.button && e.button == 1) // middle click, >IE9 + everyone else
      ){
          return;
      }

      const href = target.href;
      const url = new URL(href);
      if (url.origin === location.origin && url.pathname === location.pathname && url.hash !== location.hash) {
        e.preventDefault();
        
        const id = removeHashCharacter(url.hash);
        if (id) {
          navigateToElement(id);
        }
      }
    }

    const handleHashChange = (e) => setHash(document.location.hash);

    document.addEventListener('click', handleClick, true);
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.addEventListener('click', handleClick, true);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);


  useLayoutEffect(() => {
    if (!hash) {
        return;
    }

    navigateToElement(removeHashCharacter(hash)).then(() => {
      const url = new URL(location.href);
      url.hash = '';
      history.replaceState({}, null, url.href);
    })
  }, [hash]);

  return null;
};

export default ScrollToHashElement;