import store from "../../storage/store.js";
import ApiLoader from "../../services/ApiLoader.js";
import * as CONST from "../../services/QueryConstants.js";
import * as dispatches from "../../storage/dispatches.js";
import apiHandler from "../../services/handlers/apiHandler.js";

export function sellProduct (user_product_id, callback){
    ApiLoader.privateQueryFetch().userProductSell({user_product_id}).exec().then(apiHandler).then(result => {
        if(callback){
            let subResponse = result[CONST.USER_PRODUCT_SELL];

            if(subResponse) callback(subResponse);
        }
    });
}

export function showPopup (popup){
    store.dispatch(dispatches.popupShow(popup));
}

export function hidePopup (){
    store.dispatch(dispatches.popupHide());
}

export function setLanguageCurrent (code){
    ApiLoader.privateQueryFetch().languageKeyList({code}).exec().then(apiHandler);

    store.dispatch(dispatches.setLanguageCurrent(code));
}

export function setIsMobileVersion (isMobile){
    store.dispatch(dispatches.setIsMobileVersion(isMobile));
}


