import React, { useEffect, useRef, useState } from "react";
import { Link } from "wouter-preact";

import "./userHeaderMobile.css";
import burger from "../../../img/header/burger.svg?inline";

import UserHeaderInfo from "../UserHeaderInfo.jsx";
import MainButton from "../../button/mainButton/MainButton.jsx";
import PopupViews from "../../../popups/views/PopupViews.jsx";
import Logo from "../../logo/logo.jsx";
import { handleOpenBox } from "../../../app/UIController.jsx";
import Eye from "../eye.jsx";
import { useHistory, useLocation } from "../../../../hooks/router.js";

const UserHeaderViewMobile = (props) => {
    const headerRef = useRef();
    const [showPopup, setShowPopup] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const pathname = location.pathname;
    const isMainPage = pathname === "/";
    const { visitorsPerBox } = props.stats;
    const isBoxPage = pathname.startsWith("/box");
    const isProductPage = pathname.startsWith("/product");

    useEffect(() => {
        const header = headerRef.current;
        if (!header || !isMainPage) {
            return;
        }

        let didScroll;
        let lastScrollTop = 0;
        let delta = 5;
        let navbarHeight = 94;

        function hasScrolled() {
            var st = document.documentElement.scrollTop;

            // Make sure they scroll more than delta
            if (Math.abs(lastScrollTop - st) <= delta) return;

            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > navbarHeight) {
                header.classList.remove("nav-down");
                header.classList.add("nav-up");
            } else {
                if (
                    st + window.innerHeight <
                    document.documentElement.scrollHeight
                ) {
                    header.classList.remove("nav-up");
                    header.classList.add("nav-down");
                }
            }

            lastScrollTop = st;
        }

        const handleScroll = () => (didScroll = true);
        window.addEventListener("scroll", handleScroll);
        const interval = setInterval(function () {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            clearInterval(interval);
        };
    }, [headerRef.current, isMainPage]);

    const togglePopup = () => setShowPopup((prevState) => !prevState);

    let foundIndex = 0;

    const { box } = props;

    return (
        <>
            <div className="m-header-placeholder"></div>
            <div
                ref={headerRef}
                className={"m-header " + (isMainPage ? "sticky" : "")}
            >
                <Link to="/">
                    <Logo className="m-header-logo" />
                </Link>
                <div className="m-header-burger" onClick={props.onBurgerClick}>
                    <img src={burger} alt="Icon" />
                </div>
            </div>
            {(isBoxPage || isProductPage) && (
                <>
                    <div
                        className={`m-header m-header-with-views ${
                            props.isActiveHeader ? "active" : ""
                        }`}
                    >
                        <div className="m-header-views" onClick={togglePopup}>
                            <UserHeaderInfo
                                value={
                                    visitorsPerBox
                                        ? visitorsPerBox[foundIndex]
                                        : 0
                                }
                                icon={<Eye />}
                            />
                        </div>
                        <div className="m-header-center">
                            <MainButton
                                text={"Open box"}
                                onClickHandler={() =>
                                    handleOpenBox(box, history, true)
                                }
                            />
                        </div>
                        <div
                            className="m-header-burger"
                            onClick={props.onBurgerClick}
                        >
                            <img src={burger} alt="Icon" />
                        </div>
                    </div>
                    {showPopup && (
                        <PopupViews
                            num={
                                visitorsPerBox ? visitorsPerBox[foundIndex] : 0
                            }
                            text={"players looking this box right now!"}
                            onClickBackground={togglePopup}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default UserHeaderViewMobile;
