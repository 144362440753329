const tools = {
    randomMinMax (min, max){
        return Math.round(Math.random() * (max - min) + min);
    },
    searchParamsAsArray(searchString){
        let result = {};

        searchString.replace(/^\?/, '').split('&').forEach(item => {
            let split = item.split('=');

            result[split[0]] = split[1];
        });

        return result;
    },
    isBlockInViewport (block){
        let bounding = block.getBoundingClientRect();

        return (bounding.top > 0 && bounding.top < window.innerHeight) || (bounding.bottom > 0 && bounding.bottom < window.innerHeight);
    },
    debounceDelay (func, delay, maxDelay){
        let timeout;
        let maxTimeout;

        return function (){
            if(timeout) clearTimeout(timeout);

            let context = this, args = arguments;

            function run(){
                clearTimeout(timeout);
                clearTimeout(maxTimeout);
                timeout = null;
                maxTimeout = null;
                func.apply(context, args);
            }

            timeout = setTimeout(() => {
                run();
            }, delay);

            if(maxDelay && !maxTimeout){
                maxTimeout = setTimeout(() => {
                    run();
                }, maxDelay);
            }
        }
    },
    roundTo(roundTo, value){
        let result = value;
        let excess = value % roundTo;

        if(excess){
            result += roundTo - value % roundTo;
        }

        return result;
    },
    checkOnLatin1Range (string){
        return !/[^\u0020-\u007e\u00a0-\u00ff]/.test(string);
    }
};

export default tools;