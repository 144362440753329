import { isMobileScreen } from "../../mobile-utils";
import * as ACTION from "../actionTypes";

let initialState = {
    isMobile: isMobileScreen(),
    stats: {
        online: window.stats.clients_online,
        reward: 0,
        unpacked: window.stats.opens,
        unpacked_today: 0,
        users: window.stats.clients_total,
        wins: window.stats.wins,
    },
    categoryList: [],
    lastWinners: [],
    languageList: null,
    languageCurrent: null,
    languageKeyList: null,
    translations: window.translations,
    spinner: null,
    spinnerStatus: false,
    isHandledBtnSpin: false,
    phoneList: []
};

export default function app(state = initialState, action) {
    if(DEV) console.log('[REDUCER.ACTION]:', action);

    let newState = {...state};

    switch (action.type) {
        case ACTION.STATS_SET:
            newState.stats = {...newState.stats, ...action.stats};

            return newState;
        case ACTION.CATEGORY_SET_LIST:
            newState.categoryList = action.categoryList;

            return newState;
        case ACTION.LAST_WINNERS_SET:
            newState.lastWinners = [...action.winners];

            return newState;
        case ACTION.SPINNER_SET:
            newState.spinner = action.spinner;

            return newState;
        case ACTION.SPINNER_STATUS_SET:
            newState.spinnerStatus = action.status;

            return newState;
        case ACTION.LANGUAGE_LIST_SET:
            newState.languageList = action.list;

            return newState;
        case ACTION.LANGUAGE_KEY_LIST_SET:
            if(newState.languageKeyList === null) newState.languageKeyList = {};

            newState.languageKeyList[action.key] = action.list;

            if(!newState.languageCurrent) newState.languageCurrent = action.key;

            if(!newState.translations || state.languageCurrent !== newState.languageCurrent){
                newState.translations = newState.languageKeyList[newState.languageCurrent] ? newState.languageKeyList[newState.languageCurrent] : null;
            }

            return newState;
        case ACTION.LANGUAGE_CURRENT_SET:
            newState.languageCurrent = action.code;
            newState.translations = newState.languageKeyList ? newState.languageKeyList[action.code] : null;

            return newState;
        case ACTION.COUNTRY_LIST:
            newState.countryList = action.list;

            return newState;
        case ACTION.CURRENCY_CURRENT_SET:
            newState.currentCurrency = action.currency;

            return newState;
        case ACTION.IS_MOBILE_VERSION_SET:
            newState.isMobile = action.isMobile;

            return newState;
        case ACTION.PHONE_LIST_SET:
            newState.phoneList = action.list;

            return newState;
        default:
            return state
    }
}