import React from "react";
import { Link } from "wouter-preact";

import { Slider } from "../slider/index.jsx";

import poster1 from "../../img/reviews/review-video-1.webp";
import poster2 from "../../img/reviews/review-video-2.webp";
import poster3 from "../../img/reviews/review-video-3.webp";
import poster4 from "../../img/reviews/review-video-4.webp";
import poster5 from "../../img/reviews/review-video-5.webp";

import MainButton from "../button/mainButton/MainButton.jsx";
import ReviewPlayer from "./ReviewPlayer.jsx";

import "./reviews.css";

const Reviews = () => {
    return (
        <div className="reviews-content-wrap" id="main-reviews-block">
            <div className="reviews-title-wrap">
                <h2>Recent reviews</h2>
            </div>
            <div className="reviews-videos-wrap">
                <Slider
                    footer={
                        <div className="blaze-pagination"></div>
                    }
                    config={{
                        all: {
                            loop: true,
                            slidesToShow: 5,
                        },
                        "(max-width: 480px)": {
                            loop: false,
                            slidesToShow: 2.5,
                        },
                        "(max-width: 640px)": {
                            slidesToShow: 2.5,
                        },
                        "(max-width: 768px)": {
                            slidesToShow: 3,
                        },
                        "(max-width: 1024px)": {
                            slidesToShow: 3,
                        },
                        "(max-width: 1200px)": {
                            slidesToShow: 1.5,
                        },
                    }}
                >
                    <div>
                    <ReviewPlayer
                        src="./reviews/review-video-1.mp4"
                        poster={poster1}
                    />
                    </div>
                    <div>
                    <ReviewPlayer
                        src="./reviews/review-video-2.mp4"
                        poster={poster2}
                    />
                    </div>
                    <div>
                    <ReviewPlayer
                        src="./reviews/review-video-3.mp4"
                        poster={poster3}
                    />
                    </div>
                    <div>
                    <ReviewPlayer
                        src="./reviews/review-video-4.mp4"
                        poster={poster4}
                    />
                    </div>
                    <div>
                    <ReviewPlayer
                        src="./reviews/review-video-5.mp4"
                        poster={poster5}
                    />
                    </div>
                </Slider>
            </div>
            <div className="reviews-action-box-btn-wrap">
                <Link to="/box/surprise-box/">
                    <MainButton text={"Get Started"} />
                </Link>
            </div>
        </div>
    );
};

export default React.memo(Reviews);
