import React from "react";
import { Link } from "wouter-preact";

function NavItem (props){
    return (
        <Link to={props.link} className={props.cl || 'nav-page-item'} onClick={props.onClick}>
            {props.icon &&
                <img src={props.icon} alt="" className='nav-page-item-icon'/>
            }
            <span>{props.name}</span>
        </Link>
    )
}

export default NavItem;