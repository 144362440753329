import React, { Suspense } from "react";
import Loader from "../../../js/tools/Loader.js";
import * as CONST from "../../../services/QueryConstants.js";
import * as appController from "../../app/appController.js";
import * as UIController from "../../app/UIController.jsx";
import QueryController from "../../../services/QueryController.js";

import Info from "../../popups/info/Info.jsx";
import Footer from "../../components/userFooter/Footer.jsx";
import UserHeader from "../../components/userHeader/UserHeader.jsx";

import "./base.css";
import { withRouter } from "../../../hooks/with-router.jsx";
import { connect } from "react-redux";
import Preloader from "../../components/preloader/Preloader.jsx";
import BasePopup from "../../popups/_basePopup/BasePopup.jsx";
import MobileMenu from "../../components/mobileMenu/MobileMenu.jsx";
import LazyLoadedComponent from "../../components/lazyLoad/lazyLoad.jsx";
const PaymentPopup = React.lazy(() =>
    import("../../popups/payment/PaymentPopup.jsx")
);

class Base extends React.Component {
    constructor(props) {
        super(props);

        this.firstRender = true;

        this.state = {
            resetPopup: null,
        };

        this.checkPopupUrl();

        let isActiveHeader = false;
        let isMobile = props.isMobile;
        let scrolling = false;
        let scrollTimeout;

        this.onScroll = () => {
            if (!scrolling) {
                document.body.classList.add("is-scrolling");
                scrolling = true;
            }

            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                document.body.classList.remove("is-scrolling");
                scrolling = false;
            }, 50);

            if (
                (isMobile && window.scrollY > 120) ||
                (!isMobile && window.scrollY > 90)
            ) {
                isActiveHeader = true;
            } else {
                isActiveHeader = false;
            }

            if (this.state.isActiveHeader != isActiveHeader) {
                this.setState({ isActiveHeader: isActiveHeader });
            }
        };
    }

    componentDidMount() {
        this.execQuery();

        window._paq.push(['setCustomUrl', location.origin + this.props.location.pathname]);

        window.addEventListener("scroll", this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.execQuery();
            window.scrollTo(0, 0);
        }

        if (this.props.location.search !== prevProps.location.search) {
            this.checkPopupUrl();
        }
    }

    checkPaymentSuccess() {
        const search = new URLSearchParams(location.search);
        if (search.has("paymentSuccess")) {
            search.delete("paymentSuccess");

            window.dataLayer.push({
                event: "paymentSuccess",
            });
            this.props.history.replace({ search: search.toString() });
        }
    }

    checkPopupUrl = () => {
        if (!this.props.loggedIn) {
            return;
        }

        const search = new URLSearchParams(location.search);

        if (search.has("login")) {
            search.delete("login");
            this.props.history.push("?" + search.toString());
        } else if (search.has("signup")) {
            search.delete("signup");
            this.props.history.push("?" + search.toString());
        }
    };

    execQuery() {
        // TODO figure out loader
        //UIController.globalPreloader.show();

        QueryController.registerQuery({ type: CONST.USER_INFO });
        QueryController.exec((result) => {
            if (result.status === "error") {
                let errorText = "Ooops! Unhandled error!";

                if (result.errorType === Loader.ERROR_TYPE.NETWORK) {
                    errorText = "Network connection error! Try refresh page.";
                }

                appController.showPopup(
                    <Info onBackgroundClick={null}>{errorText}</Info>
                );
            }

            //UIController.globalPreloader.hide();
        });
    }

    generateLoginSignupPopup() {
        const search = new URLSearchParams(this.props.location.search);

        if (search.has("login")) {
            const onBackgroundClick = () =>
                UIController.loginPopup.hide(
                    this.props.location,
                    this.props.history
                );

            return (
                <BasePopup onClickBackground={onBackgroundClick}>
                    <LazyLoadedComponent
                        key="login"
                        width="560px"
                        height="667px"
                        triggers={{ observer: false }}
                        load={() => import("../../popups/login/Login.jsx")}
                        loading={<Preloader/>}
                    >
                        {(Login) => <Login />}
                    </LazyLoadedComponent>
                </BasePopup>
            );
        }

        if (search.has("signup")) {
            const onBackgroundClick = () =>
                UIController.signupPopup.hide(
                    this.props.location,
                    this.props.history
                );

            return (
                <BasePopup onClickBackground={onBackgroundClick}>
                    <LazyLoadedComponent
                        key="signup"
                        width="560px"
                        height="772px"
                        triggers={{ observer: false }}
                        load={() => import("../../popups/signup/Signup.jsx")} 
                        loading={<Preloader/>}>
                        {(Signup) => <Signup />}
                    </LazyLoadedComponent>
                </BasePopup>
            );
        }

        return null;
    }

    generatePaymentPopup() {
        const search = new URLSearchParams(this.props.location.search);

        if (search.has("payment") && this.props.loggedIn) {
            return (
                <Suspense>
                    <PaymentPopup
                        onClickBackground={UIController.paymentPopup.hide.bind(
                            null,
                            this.props.location,
                            this.props.history
                        )}
                    />
                </Suspense>
            );
        }
    }

    render() {
        const { children, popup, isMobile } = this.props;

        if (this.firstRender) {
            setTimeout(() => {
                this.checkPaymentSuccess();
            }, 1);
            this.firstRender = false;
        }

        return (
            <>
                <div className={isMobile ? "page mobile" : "page"}>
                    <UserHeader isActiveHeader={this.state.isActiveHeader} />
                    <div className="page-content">{children}</div>
                    {this.generateLoginSignupPopup()}
                    {this.generatePaymentPopup()}
                    {popup}
                    <MobileMenu />
                </div>
                <Footer />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        isMobile: state.app.isMobile,
        loggedIn: !!state.user.access_token,
        popup: state.ui.popup,
    };
}

export default connect(mapStateToProps)(withRouter(Base));
