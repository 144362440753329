import * as ACTION from "./actionTypes";
import AppError from "../errors/AppError.js";

// BOX

export function setBoxItem (box){
    return {type: ACTION.BOX_ITEM_SET, box};
}

export function setBoxList (boxes){
    return {type: ACTION.BOX_LIST_SET, boxes};
}

export function setBoxItemProducts (products){
    return {type: ACTION.BOX_ITEM_PRODUCTS_SET, products};
}

export function setBoxRecommendedList (boxes){
    return {type: ACTION.BOX_RECOMMENDED_LIST_SET, boxes};
}

export function setBoxToOpen (box){
    return {type: ACTION.BOX_TO_OPEN_SET, box};
}

// PRODUCT

export function setProductItem (product){
    return {type: ACTION.PRODUCT_ITEM_SET, product};
}

// export function setProductList (boxes){
//     return {type: ACTION.PRODUCT_LIST_SET, boxes};
// }

export function setProductItemBoxes (products){
    return {type: ACTION.PRODUCT_ITEM_BOXES_SET, products};
}

export function setProductRecommendedList (products){
    return {type: ACTION.PRODUCT_RECOMMENDED_LIST_SET, products};
}

// USER

export function setUserData (data){
    return {type: ACTION.USER_SET_DATA, data};
}

export function userLogout (){
    return {type: ACTION.USER_LOGOUT};
}

export function userProductSell (user_product_id){
    return {type: ACTION.USER_PRODUCT_SELL, user_product_id};
}

export function setUserProductList (list){
    return {type: ACTION.USER_PRODUCT_LIST_SET, list};
}

export function addUserProductList (list){
    return {type: ACTION.USER_PRODUCT_LIST_ADD, list};
}

export function userProductDelivery (user_product_id){
    return {type: ACTION.USER_PRODUCT_DELIVERY, user_product_id};
}

export function setUserAddressList (list){
    return {type: ACTION.USER_ADDRESS_LIST_SET, list};
}

export function setUserAddressInfo (info){
    return {type: ACTION.USER_ADDRESS_INFO_SET, info};
}

export function userAddressAdd (address){
    return {type: ACTION.USER_ADDRESS_ADD, address};
}

export function userAddressRemove (id){
    return {type: ACTION.USER_ADDRESS_REMOVE, id};
}

export function userAddressUpdate (address){
    return {type: ACTION.USER_ADDRESS_UPDATE, address}
}

export function userTransactionsSet (list){
    return {type: ACTION.USER_TRANSACTIONS_SET, list}
}

export function userTransactionsAdd (list){
    return {type: ACTION.USER_TRANSACTIONS_ADD, list}
}

export function userSeedListSet (list){
    return {type: ACTION.USER_SEED_LIST_SET, list}
}

// REPLENISH

export function replenishSet (replenish){
    return {type: ACTION.USER_REPLENISH_SET, replenish}
}

// CATEGORY

export function setCategoryList (categoryList){
    return {type: ACTION.CATEGORY_SET_LIST, categoryList: Array.isArray(categoryList) ? categoryList : []};
}

// STATS

export function setStats (stats){
    return {type: ACTION.STATS_SET, stats};
}

// LAST_WINNERS

export function setLastWinners (winners){
    return {type: ACTION.LAST_WINNERS_SET, winners: Array.isArray(winners) ? winners : []}
}

// SPINNER

export function setSpinner (spinner){
    return {type: ACTION.SPINNER_SET, spinner}
}

export function setSpinnerStatus (status){
    return {type: ACTION.SPINNER_STATUS_SET, status}
}

// POPUPS

export function popupShow (popup){
    return {type: ACTION.POPUP_SHOW, popup};
}

export function popupHide (){
    return {type: ACTION.POPUP_HIDE};
}

export function popupLoginShow (popup){
    return {type: ACTION.POPUP_LOGIN_SHOW, popup};
}

export function popupLoginHide (){
    return {type: ACTION.POPUP_LOGIN_HIDE};
}

export function popupSignupShow (popup){
    return {type: ACTION.POPUP_SIGNUP_SHOW, popup};
}

export function popupSignupHide (){
    return {type: ACTION.POPUP_SIGNUP_HIDE};
}

export function popupPaymentShow (popup){
	return {type: ACTION.POPUP_PAYMENT_SHOW, popup};
}

export function popupPaymentHide (){
	return {type: ACTION.POPUP_PAYMENT_HIDE};
}

export function showGlobalPreloader (preloader){
    return {type: ACTION.GLOBAL_PRELOADER_SHOW, preloader};
}

export function hideGlobalPreloader (){
    return {type: ACTION.GLOBAL_PRELOADER_HIDE};
}

// LANGUAGE

export function setLanguageCurrent (code){
    return {type: ACTION.LANGUAGE_CURRENT_SET, code};
}

export function setCurrencyCurrent (currency){
    return {type: ACTION.CURRENCY_CURRENT_SET, currency};
}

export function setLanguageList (list){
    return {type: ACTION.LANGUAGE_LIST_SET, list};
}

export function setLanguageKeyList (key, list){
    return {type: ACTION.LANGUAGE_KEY_LIST_SET, key, list};
}

export function setCountryList (list){
    return {type: ACTION.COUNTRY_LIST, list};
}

// PRIZE

// export function setPrizeList (list){
//     return {type: ACTION.PRIZE_LIST_SET, list};
// }

export function addPrizeItem (prize){
    return {type: ACTION.PRIZE_LIST_ADD_ITEM, prize};
}

export function addPrizeFreeItem (prize){
    return {type: ACTION.PRIZE_LIST_FREE_ADD_ITEM, prize};
}

export function markPrizeAsSold (id){
    return {type: ACTION.PRIZE_LIST_MARK_SOLD_ITEM, id};
}

// export function setCurrentPrize (currentPrize){
//     return {type: ACTION.PRIZE_CURRENT_SET, currentPrize};
// }

// MOBILE

export function setIsMobileVersion (isMobile){
    return {type: ACTION.IS_MOBILE_VERSION_SET, isMobile};
}

// ERROR

export function dispatchError (errorType, errorData){
    return {type: ACTION.ERROR, error: new AppError(errorType, errorData)};
}

// export function dispatchHandledError (errorId){
//     return {type: ACTION.ERROR_HANDLED, id: errorId};
// }


// PHONE LIST

export function phoneListSet (list){
    return {type: ACTION.PHONE_LIST_SET, list};
}

export function phoneFormSet (phone){
    return {type: ACTION.PHONE_FORM_SET, phone};
}

// ALERT

export function addNotification (notification){
    return {type: ACTION.ADD_NOTIFICATION, notification};
}

export function clearNotification (){
    return {type: ACTION.CLEAR_NOTIFICATION};
}