import * as CONST from "../QueryConstants";
import store from "../../storage/store.js";
import {errorHandler} from "./errorHandler.js";
import * as dispatches from "../../storage/dispatches.js";
import AppError from "../../errors/AppError.js";

function globalResponseErrorHandler (response){
    const {code} = response;

    if(code === 401){
        store.dispatch(dispatches.userLogout());
    }
    else if(code === 403){
        // console.log('code === 403');
        store.dispatch(dispatches.userLogout());
    }
    else if(code >= 400 && code < 500){
        store.dispatch(dispatches.userLogout());
    }
    else{
        store.dispatch(dispatches.dispatchError(AppError.TYPE.GLOBAL_UNKNOWN, {code}));
    }
}



function apiCategoryBoxInfoHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);
}

function apiCategoriesBoxHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            return store.dispatch(dispatches.setCategoryList([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.setCategoryList(response._list));
}

function apiBoxesHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            return store.dispatch(dispatches.setBoxList([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.setBoxList(response._list));
}

function apiStatsHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    let {users, unpacked, online} = response;

    store.dispatch(dispatches.setStats({users, unpacked, online}));
}

function apiUserInfoHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    let {status, ...userData} = response;

    store.dispatch(dispatches.setUserData(userData));
}

function apiUserProfileHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    let {status, ...userData} = response;

    store.dispatch(dispatches.setUserData(userData));
}

function apiUserProfileUpdateHandler (response, params){
    if(response.status === 'error') return errorHandler(response);

    if(params){
        store.dispatch(dispatches.setUserData(params));
    }
}

function apiUserProfilePasswordUpdateHandler (response, params){
    if(response.status === 'error') return errorHandler(response);
}

function apiUserProductListHandler (response, params, localParams){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            return store.dispatch(dispatches.setUserProductList([]));
        }
        else{
            return errorHandler(response);
        }
    }

    if(localParams.concat){
        store.dispatch(dispatches.addUserProductList(response._list));
    }
    else{
        store.dispatch(dispatches.setUserProductList(response._list));
    }
}

function apiUserProductSellHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    if(response.user_product_id){
        store.dispatch(dispatches.userProductSell(response.user_product_id));
        store.dispatch(dispatches.markPrizeAsSold(response.user_product_id));
        store.dispatch(dispatches.addNotification({type: 'balance', title: '', description: '', translateKeys: {description: 'alert.product.sell'}, amount: response.amount}));
    }
}

function apiProductDeliveryHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    store.dispatch(dispatches.userProductDelivery(response.user_product_id));
}

function apiUserAddressListHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            return store.dispatch(dispatches.setUserAddressList([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.setUserAddressList(response._list));
}

function apiUserAddressInfoHandler(response) {
    if ( response && response.status && response.status === 'error' ) {
        if ( response.code === 404 ) {
            return store.dispatch(dispatches.setUserAddressInfo({}));
        }
        else{
            return errorHandler(response);
        }
    }
    
    store.dispatch(dispatches.setUserAddressInfo(response));
}

function apiUserAddressAddHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    const {status, ...rest} = response;

    store.dispatch(dispatches.userAddressAdd(rest));
}

function apiUserAddressUpdateHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);
    
    const {status, ...rest} = response;

    if(rest && rest.address){
        store.dispatch(dispatches.userAddressUpdate(rest));
    }
}

function apiUserSeedRevealHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    const {status, ...rest} = response;

    // store.dispatch(dispatches.userAddressUpdate(rest));
}

function apiUserSeedListHandler (response, params, localParams){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            return store.dispatch(dispatches.userSeedListSet([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.userSeedListSet(response._list));
}


function apiUserTransactionsHandler (response, params, localParams){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            return store.dispatch(dispatches.userTransactionsSet([]));
        }
        else{
            return errorHandler(response);
        }
    }

    if(localParams.concat){
        store.dispatch(dispatches.userTransactionsAdd(response._list));
    }
    else{
        store.dispatch(dispatches.userTransactionsSet(response._list));
    }
}

function apiUserReplenishHandler (response){
    // if(response && response.status && response.status === 'error') return errorHandler(response);

    // console.log(response);
}

function apiUserAddressRemoveHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    store.dispatch(dispatches.userAddressRemove(response.id));
}

function apiLastWinnersHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            return store.dispatch(dispatches.setLastWinners([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.setLastWinners(response._list));
}

function apiBoxInfoHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    const {status, ...rest} = response;

    store.dispatch(dispatches.setBoxItem(rest));
}

function apiBoxOpenHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);
}

function apiBoxProductsHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            store.dispatch(dispatches.setBoxItemProducts([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.setBoxItemProducts(response._list));
}

function apiBoxRecommendedListHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            store.dispatch(dispatches.setBoxRecommendedList([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.setBoxRecommendedList(response._list));
}

function apiProductInfoHandler (response){
    if(response && response.status && response.status === 'error') return errorHandler(response);

    const {status, ...rest} = response;

    store.dispatch(dispatches.setProductItem(rest));
}

function apiBoxWithProductHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            store.dispatch(dispatches.setProductItemBoxes([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.setProductItemBoxes(response._list));
}

function apiProductRecommendedHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            store.dispatch(dispatches.setProductRecommendedList([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.setProductRecommendedList(response._list));
}

function apiLanguageListHandler (response){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            store.dispatch(dispatches.setLanguageList([]));
        }
        else{
            return errorHandler(response);
        }
    }

    // console.log(response);

    store.dispatch(dispatches.setLanguageList(response._list));
}

function apiLanguageKeyListHandler (response, params){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            store.dispatch(dispatches.setLanguageKeyList(params.code, []));
        }
        else{
            return errorHandler(response);
        }
    }

    function convertList (list){
        let result = {};

        for(let i = 0; i < list.length; i++){
            result[list[i].key] = list[i].value;
        }

        return result;
    }

    let translations = convertList(response._list);

    store.dispatch(dispatches.setLanguageKeyList(params.code, translations));
}

function apiPhoneListHandler (response, params){
    if(response && response.status && response.status === 'error'){
        if(response.code === 404){
            store.dispatch(dispatches.phoneListSet([]));
        }
        else{
            return errorHandler(response);
        }
    }

    store.dispatch(dispatches.phoneListSet(response._list));
}

function apiCountryList (response){
    if(response && response.status && response.status === 'error'){
        return errorHandler(response);
    }
    // console.log('country list', response);
    store.dispatch(dispatches.setCountryList(response._list));
}

function apiAddressList (response){
    if(response && response.status && response.status === 'error'){
        return errorHandler(response);
    }
}

function apiAddressChildren (response){
    if(response && response.status && response.status === 'error'){
        return errorHandler(response);
    }
}

const handlersMap = {
    [CONST.USER]: apiUserInfoHandler,
    [CONST.USER_INFO]: apiUserInfoHandler,
    [CONST.USER_PROFILE]: apiUserProfileHandler,
    [CONST.USER_PROFILE_UPDATE]: apiUserProfileUpdateHandler,
    [CONST.USER_PROFILE_PASSWORD_UPDATE]: apiUserProfilePasswordUpdateHandler,
    [CONST.USER_PRODUCT_LIST]: apiUserProductListHandler,
    [CONST.USER_PRODUCT_SELL]: apiUserProductSellHandler,
    [CONST.USER_PRODUCT_DELIVERY]: apiProductDeliveryHandler,
    [CONST.USER_ADDRESS_LIST]: apiUserAddressListHandler,
    [CONST.USER_ADDRESS_INFO]: apiUserAddressInfoHandler,
    [CONST.USER_ADDRESS_ADD]: apiUserAddressAddHandler,
    [CONST.USER_ADDRESS_REMOVE]: apiUserAddressRemoveHandler,
    [CONST.USER_ADDRESS_UPDATE]: apiUserAddressUpdateHandler,
    [CONST.USER_SEED_REVEAL]: apiUserSeedRevealHandler,
    [CONST.USER_SEED_LIST]: apiUserSeedListHandler,
    [CONST.USER_TRANSACTIONS]: apiUserTransactionsHandler,
    [CONST.USER_REPLENISH]: apiUserReplenishHandler,
    [CONST.CATEGORY_BOX_BOXES]: apiBoxesHandler,
    [CONST.CATEGORY_BOX_LIST]: apiCategoriesBoxHandler,
    [CONST.CATEGORY_BOX_INFO]: apiCategoryBoxInfoHandler,
    [CONST.BOX_INFO]: apiBoxInfoHandler,
    [CONST.BOX_OPEN]: apiBoxOpenHandler,
    [CONST.BOX_ALL]: apiBoxesHandler,
    [CONST.BOX_PRODUCTS]: apiBoxProductsHandler,
    [CONST.BOX_RECOMMENDED]: apiBoxRecommendedListHandler,
    [CONST.BOX_WITH_PRODUCT]: apiBoxWithProductHandler,
    [CONST.PRODUCT_INFO]: apiProductInfoHandler,
    [CONST.PRODUCT_RECOMMENDED]: apiProductRecommendedHandler,
    [CONST.STATS]: apiStatsHandler,
    [CONST.LAST_WINNERS]: apiLastWinnersHandler,
    [CONST.LANGUAGE_LIST]: apiLanguageListHandler,
    [CONST.LANGUAGE_KEY_LIST]: apiLanguageKeyListHandler,
    [CONST.PHONE_LIST]: apiPhoneListHandler,
    [CONST.COUNTRY_LIST]: apiCountryList,
    [CONST.ADDRESS_LIST]: apiAddressList,
    [CONST.ADDRESS_CHILDREN]: apiAddressChildren
};

const apiHandler = (result) => {
    return new Promise((resolve, reject) => {
        let {status, ...response} = result.response;
        let params = result.params || {};
        let localParams = result.localParams || {};

        if(!status || status !== 'success'){
            globalResponseErrorHandler(response);
            resolve({});
            return;
        }

        let responseKeys = Object.keys(response);
        let callbackResult = {};

        responseKeys.forEach(key => {
            if(handlersMap[key]){
                handlersMap[key](response[key], params[key], localParams[key]);
            }
            else{
                if(DEV) console.warn('apiHandler() - unhandled response key: '+key);
            }

            // if(response[key].status === 'success'){
                callbackResult[key] = response[key];
            // }
        });

        resolve(callbackResult);
    });
};

export default apiHandler;