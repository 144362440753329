import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "wouter-preact";

import { userLogout } from "../../../storage/dispatches.js";
import * as UIController from "../../app/UIController.jsx";
import i18n from "../../../i18n/i18n.js";

import ProtectedComponent from "../protected/ProtectedComponent.jsx";
import NavItem from "../navItem/NavItem.jsx";

import iconLogout from "../../img/icons/logout.svg";
import iconLogin from "../../img/icons/login_color.svg";

import online from "../../svg/new/_header_online.svg";
import usersIcon from "../../svg/new/_header_users.svg";
import usersWon from "../../svg/new/_header_won.svg";
import usersBox from "../../svg/new/_header_box.svg";

import "./mobileMenu.css";
import Logo from "../logo/logo.jsx";
import { useHistory, useLocation } from "../../../hooks/router.js";
import { disableBodyScroll } from "../../../scroll.js";

const MobileMenu = (props) => {
    const history = useHistory();
    const location = useLocation();

    const getMobileMenuVisibility = () => {
        const search = new URLSearchParams(location.search);
        return search.has("menu");
    };

    const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false);

    useEffect(() => {
        if (mobileMenuVisibility) {
            return disableBodyScroll();
        }
    }, [mobileMenuVisibility]);

    useEffect(() => {
        const updateMenuVisibility = () => {
            const isVisibleMobileMenu = getMobileMenuVisibility();
            setMobileMenuVisibility(isVisibleMobileMenu);
        };

        updateMenuVisibility();
    }, [location.search]);

    const hideMobileMenu = UIController.mobileMenu.hide.bind(
        null,
        location,
        history
    );

    const getLoginSignupComponent = () => (
        <div className="m-user-actions">
            <Link to="?signup">
                <span className="icon">
                    <img
                        src={iconLogin}
                        alt={i18n.get("button.text.loginSignup")}
                        loading="lazy"
                    />
                </span>
                <span className="text">
                    {i18n.get("button.text.loginSignup")}
                </span>
            </Link>
        </div>
    );

    const { stats } = props;
    const fullStats = [
        {
            icon: usersWon,
            iconSize: "large",
            text: "worth of prizes won",
            value: `₦${stats.wins || 0}`,
        },
        {
            icon: usersBox,
            iconSize: "large",
            text: "boxes opened",
            value: stats.unpacked,
        },
        {
            icon: usersIcon,
            text: "users with us",
            value: stats.users,
        },
    ];

    return (
        <>
            <div
                className={
                    mobileMenuVisibility
                        ? "mobile-menu-bg show"
                        : "mobile-menu-bg"
                }
                onClick={hideMobileMenu}
            />
            <div
                className={
                    mobileMenuVisibility ? "mobile-menu show" : "mobile-menu"
                }
            >
                <Link to="/" className="m-header-logo">
                    <Logo />
                </Link>
                <ProtectedComponent>
                    <div
                        className="m-user-panel-btn-logout"
                        onClick={() => props.onLogout()}
                    >
                        <img src={iconLogout} alt="" loading="lazy" />
                    </div>
                </ProtectedComponent>
                <div className="m-nav-list m-nav-list-first">
                    <NavItem
                        name={i18n.get("button.text.boxes")}
                        link="/#main-boxes-block"
                        onClick={hideMobileMenu}
                    />
                    <NavItem
                        name={`${i18n.get("how.it.works")} / ${i18n.get(
                            "faq"
                        )}`}
                        link="/#main-how-it-works-block"
                        onClick={hideMobileMenu}
                    />
                    <NavItem
                        name={i18n.get("reviews")}
                        link="/#main-reviews-block"
                        onClick={hideMobileMenu}
                    />
                    <NavItem
                        name={i18n.get("button.text.win.stats")}
                        link="/#main-win-stats-block"
                        onClick={hideMobileMenu}
                    />
                    <NavItem
                        name={i18n.get("text.about.us")}
                        link="/#main-about-us-block"
                        onClick={hideMobileMenu}
                    />
                    <NavItem
                        name={i18n.get("text.contact.us")}
                        link="/#main-contact-us-block"
                        onClick={hideMobileMenu}
                    />
                    <NavItem
                        name={i18n.get("text.information.for.user.1")}
                        link="/terms"
                        onClick={hideMobileMenu}
                    />
                </div>
                <ProtectedComponent
                    nonAuthComponent={getLoginSignupComponent()}
                >
                    <div className="m-nav-list">
                        <NavItem
                            name={i18n.get("nav.link.profile")}
                            link="/profile/"
                            onClick={hideMobileMenu}
                        />
                        <NavItem
                            name={i18n.get("nav.link.address")}
                            link="/address/"
                            onClick={hideMobileMenu}
                        />
                        <NavItem
                            name={i18n.get("nav.link.inventory")}
                            link="/inventory/got/"
                            onClick={hideMobileMenu}
                        />
                        <NavItem
                            name={i18n.get("nav.link.transactions")}
                            link="/transactions/"
                            onClick={hideMobileMenu}
                        />
                    </div>
                </ProtectedComponent>
                <div className="m-nav-list m-nav-stats-list">
                    <Link to="/#main-win-stats-block" onClick={hideMobileMenu}>
                        {fullStats.map(({ icon, iconSize, text, value }) => (
                            <div key={text} className="m-nav-stats-list-item">
                                <div
                                    className={`icon-wrap ${
                                        iconSize === "large"
                                            ? "icon-large-wrap"
                                            : ""
                                    }`}
                                >
                                    <img src={icon} alt="Icon" loading="lazy" />
                                </div>
                                <div className="text-wrap">
                                    <span className="value">{value}</span>
                                    <span className="text">{text}</span>
                                </div>
                            </div>
                        ))}

                        <div className="m-nav-stats-list-item">
                            <div className="icon-wrap">
                                <img src={online} alt="Icon" loading="lazy" />
                            </div>
                            <div className="text-wrap row">
                                <span className="value">{stats.online}</span>
                                <span className="text">&nbsp;online</span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        i18n: state.app.translations,
        stats: state.app.stats,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onLogout: () => {
            dispatch(userLogout());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
