import * as actionTypes from "../actionTypes";

let initialState = {
	list: [],
	isNotificationList: false
};

export default function notification(state = initialState, action) {
	let newState = {...state};

	switch (action.type) {
		case actionTypes.ADD_NOTIFICATION:
			newState.list.push(action.notification);
			newState.isNotificationList = true;

			return newState;
		case actionTypes.CLEAR_NOTIFICATION:
			newState.list = [];
			newState.isNotificationList = false;

			return newState;
		default:
			return state;
	}
}