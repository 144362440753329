import React, { useEffect, useRef, useState } from "react";

import "./reviewPlayer.css";

import playButton from "../../../europe/svg/new/play_button.svg?inline";
import Preloader from "../preloader/Preloader";

const ReviewPlayer = ({ src, poster }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    const containerRef = useRef(null)
    const [startLoading, setStartLoading] = useState(false);
    const [posterLoaded, setPosterLoaded] = useState(false);

    useEffect(() => {
        const video = videoRef.current;

        if (!video) {
            return;
        }

        const handlePause = () => setIsPlaying(false);
        video.addEventListener("pause", handlePause);

        return () => video.removeEventListener("pause", handlePause);
    }, [videoRef.current]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setStartLoading(() => true);

                observer.unobserve(entry.target);
                observer.disconnect();
            }
          });
        });
    
        if (containerRef.current) {
          observer.observe(containerRef.current);
        }
    
        return () => {
          if (containerRef.current) {
            observer.unobserve(containerRef.current);
          }
          observer.disconnect();
        };
      }, []);


    useEffect(() => {
        if (!startLoading) {
            return;
        }

        const onLoad = () => setPosterLoaded(true);

        const img = new Image();
        img.src = poster;
        img.onload = onLoad;
        if (img.complete) {
            onLoad();
        }
    }, [startLoading, setPosterLoaded]);
    

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    return (
        <div ref={containerRef} className="video-container">
            {!posterLoaded ? <Preloader />
                : (<>
                    <video
                        ref={videoRef}
                        preload="none"
                        poster={poster}
                        controls={isPlaying}
                        className="video-element"
                    >
                        <source src={src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {!isPlaying && (
                        <div className="play-button-shade" onClick={handlePlay}>
                            <img src={playButton} alt="Play" />
                        </div>
                    )}
                </>)
            }
        </div>
    );
};

export default ReviewPlayer;
