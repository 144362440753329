import React from "react";

import "./userHeaderInfo.css";

function UserHeaderInfo(props) {
    if (props.link) {
        return (
            <a href={props.link} className="user-header-info">
                <div className="user-header-info-icon">
                    <img src={props.icon} alt="Icon"/>
                </div>
                <div className="user-header-info-data">
                    <span className="user-header-info-value">{props.value}</span>
                    <span className="user-header-info-caption">{props.caption}</span>
                </div>
            </a>
        )
    }

    return (
        <div className="user-header-info">
            <div className="user-header-info-icon">
                {props.icon}
            </div>
            <div className="user-header-info-data">
                <span className="user-header-info-value">{props.value}</span>
                <span className="user-header-info-caption">{props.caption}</span>
            </div>
        </div>
    )
}

export default UserHeaderInfo;
