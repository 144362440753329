import React, { Component } from 'react';

import aboutUsImgOne from '../../img/aboutus/about-us-img-1.webp';
import aboutUsImgTwo from '../../img/aboutus/about-us-img-2.webp';

import './aboutus.css';

class AboutUs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="aboutus-content-wrap" id="main-about-us-block">
        <h2>About us</h2>
        <div className="items-wrap">
          <div className="item">
            <div className="img-wrap">
              <img loading="lazy" src={aboutUsImgOne} alt="About Us" />
            </div>
            <div className="text-wrap">
              <h3>Our company & game</h3>
              <p className="about-us-paragraph">
                Our gambling game company is founded by young entrepreneur Atiku
                Okonkwo who coming from a poor background was lucky to win good
                sum of money in a lottery back in 2017. This flick of luck
                allowed to secure investment for his own type of lottery game
                which came to be Lucky Box.
              </p>
              <p className="about-us-paragraph">
                Box is a virtual container that has one of the products listed
                in the specific category. By spending a price of a beer bottle
                to open a box - you win expensive, stylish & trendy items at a
                fraction of the cost you’d see in online stores. All gifts that
                are listed are in stock and ready to be shipped.
              </p>
            </div>
          </div>
          <div className="item">
            <div className="text-wrap">
              <h3>Updates & future plans</h3>
              <p className="about-us-paragraph">
                This was made possible by partnering with major retailers &
                repurposing their unsold stock merchandise to be offered as
                prizes in a lottery game. This win-win business model allowed
                for fast growth which resulted in opening of big 20,000 square
                feet warehouse located in Igando, northwest of Lagos City.
              </p>
              <p className="about-us-paragraph">
                Due to lack of logistics infrastructure across Nigeria, we have
                also built our own logistics department in 2023 which made it
                possible to deliver products directly warehouse-to-home for our
                players. After acquiring overseas investments from overseas fund
                we are expanding to other countries with a plan to cover all
                African continent by year 2025.
              </p>
            </div>
            <div className="img-wrap">
              <img loading="lazy" src={aboutUsImgTwo} alt="About Us" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
