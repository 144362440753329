import React, {useMemo, useState} from "react";
import {Link} from "wouter-preact";
import MainButton from "../button/mainButton/MainButton.jsx";

import "./howItWorks.css";
import howItWorksPoster from "../../img/howItWorks/how_it_works_poster.webp";

import register from "../../img/howItWorks/_register.svg";
import deposit from "../../img/howItWorks/_deposit.svg";
import submit from "../../img/howItWorks/_submit.svg";
import choose from "../../img/howItWorks/_choose.svg";
import win from "../../img/howItWorks/_win.svg";
import stars from "../../img/howItWorks/howSectionStars.svg";
import starsTop from "../../img/howItWorks/howSectionStarsMobileTop.svg";
import starsBottom from "../../img/howItWorks/howSectionStarsMobileBottom.svg";
import howItWorks from "../../../../../public/howitworks/howitworks.mp4"
import { useSelector } from "react-redux";
import i18n from "../../../i18n/i18n";

const HowItWorksView = () => {
    const [isHowTabActive, setIsHowTabActive] = useState(true);

    const isMobile = useSelector((state) => state.app.isMobile);

    const labels = useMemo(() => ([
        {icon: register, label: i18n.get('text.hiw.step.one')},
        {icon: deposit, label: i18n.get('text.hiw.step.two')},
        {icon: submit, label: i18n.get('text.hiw.step.three')},
        {icon: choose, label: i18n.get('text.hiw.step.four')},
        {icon: win, label: i18n.get('text.hiw.step.five')},
    ]), []);

    return (
        <div
            className="how-section"
            id="main-how-it-works-block"
        >
            <div className="how-stars">
                {isMobile ? (<>
                        <div className="how-stars-top">
                            <img src={starsTop} alt="Stars" loading="lazy"/>
                        </div>
                        <div className="how-stars-bottom">
                            <img src={starsBottom} alt="Stars" loading="lazy"/>
                        </div>
                    </>) : <img src={stars} alt="Stars" loading="lazy"/>
                }
            </div>
            <div className="how-tabs">
                <div className={`how-title ${isHowTabActive ? 'active' : null}`}
                     onClick={() => setIsHowTabActive(true)}>{i18n.get('how.it.works')}</div>
                <div className={`how-title ${!isHowTabActive ? 'active' : null}`}
                     onClick={() => setIsHowTabActive(false)}>{i18n.get('FAQ') || 'FAQ'}</div>
            </div>
            <div className="how-tabs-content">
                {isHowTabActive
                    ? (
                        <div className="how-tab-content-wrap">
                            <div className="how-list-wrap">
                                {labels.map((x, index) => (
                                    <div className="item" key={index}>
                                        <img src={x.icon} alt=""/>
                                        <div className="label">
                                            <span className="id">
                                                {++index}
                                            </span>
                                            <span className="text">
                                                {x.label}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="how-tab-box-btn-wrap">
                                <Link to="/box/surprise-box/">
                                    <MainButton text={'Get Started'} />
                                </Link>
                            </div>
                            <div className="video-wrap">
                                <video controls preload="none" poster={howItWorksPoster}>
                                    <source src={howItWorks} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )
                    : (
                        <div className="how-tab-content-wrap">
                            <div className="how-faq-list">
                                <div className="how-faq-list-item">
                                    <div className="num">
                                        <h3>1</h3>
                                    </div>
                                    <div className="text">
                                        <h3>What if I want to change the product that I won?</h3>
                                        <p>You can sell the product back to us for the market price in one click. 
                                            The money will instantly get credited to your balance. 
                                            Then you can try another box to get a prize you want.</p>
                                    </div>
                                </div>
                                <div className="how-faq-list-item">
                                    <div className="num">
                                        <h3>2</h3>
                                    </div>
                                    <div className="text">
                                        <h3>What if I requested several prizes at once to be delivered to my home address?</h3>
                                        <p>If you’ve requested delivery for more than 3 prizes they will arrive in a batch. 
                                            Please, note batch deliveries may take longer than single prize deliveries. 
                                            Alternatively we offer an option to come pick up prizes at one of our Lucky Box branches in your town. 
                                            You can locate the closest branch in Contact Us page.</p>
                                    </div>
                                </div>
                                <div className="how-faq-list-item">
                                    <div className="num">
                                        <h3>3</h3>
                                    </div>
                                    <div className="text">
                                        <h3>What if I don’t understand the rules and never played games like this before?</h3>
                                        <p>The game is so simple. You just click and open a box. You get instant results - that’s why everybody loves the game. 
                                            You can see hundreds of our players posted video-reviews where they describe the process and how easy it is to play.</p>
                                    </div>
                                </div>
                                <div className="how-faq-list-item">
                                    <div className="num">
                                        <h3>4</h3>
                                    </div>
                                    <div className="text">
                                        <h3>What if I’m afraid to lose?</h3>
                                        <p>You pay price of a beer bottle to win prizes like new Apple Macbook. 
                                            You get huge deals for pennies on all the coolest products. 
                                            Value for risk ratio is always in player’s favour.</p>
                                    </div>
                                </div>
                                <div className="how-faq-list-item">
                                    <div className="num">
                                        <h3>5</h3>
                                    </div>
                                    <div className="text">
                                        <h3>What if I don’t trust gambling websites?</h3>
                                        <p>We always advice our customers check the credentials of any casino they engage with. 
                                            We are the licensed & registered gambling service provider that employs a team of 27 
                                            staff where each member of our team is working hard to provide the most safe & 
                                            impeccable gaming experience to ensure our customers satisfaction.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default HowItWorksView;
