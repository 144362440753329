import React, { useCallback } from "react";
import { Link } from "wouter-preact";

import "./mainButton.css";
import preloaderIcon from "../../../svg/preloader.svg";

const MainButton = ({
    text,
    type,
    isSale,
    link,
    styleButton,
    styleText,
    submit,
    loader,
    onClickHandler,
}) => {
    const onClickFunc = useCallback(
        (e) => onClickHandler && onClickHandler(e),
        [onClickHandler]
    );

    const className = ["main-button"];
    const loaderIcon = (
        <img src={preloaderIcon} alt="" className="main-button-loader" />
    );

    if (loader) {
        className.push("main-btn-event-none");
    }

    switch (type) {
        case "primary":
            className.push("main-button-primary");
            break;
        case "disabled":
            className.push("main-button-disabled");
            break;
        case "transparent":
            className.push("main-button-transparent");
            break;
        case "gray":
            className.push("main-button-gray");
            break;
        case "transparent-gray":
            className.push("main-button-transparent-gray");
            break;
        default:
            className.push("main-button-primary");
    }

    if (isSale) className.push(" main-button-sale");

    if (submit) {
        return (
            <button
                type="submit"
                className={className.join(" ")}
                style={styleButton}
                onClick={onClickFunc}
            >
                <div className="main-button-text" style={styleText}>
                    {loader ? loaderIcon : text}
                </div>
            </button>
        );
    }

    if (link) {
        return (
            <Link
                to={link}
                className={className.join(" ")}
                style={styleButton}
                onClick={onClickFunc}
            >
                <div className="main-button-text" style={styleText}>
                    {loader ? loaderIcon : text}
                </div>
            </Link>
        );
    }

    return (
        <div
            className={className.join(" ")}
            style={styleButton}
            onClick={onClickFunc}
        >
            <div className="main-button-text" style={styleText}>
                {loader ? loaderIcon : text}
            </div>
        </div>
    );
};

export default MainButton;
