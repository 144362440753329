import * as actionTypes from "../actionTypes";

let initialState = {
    currentError: null,
    historyErrors: []
};

export default function error(state = initialState, action) {
    // console.log('[UI.REDUCER.ACTION]:', action);

    let newState = {...state};

    switch (action.type) {
        case actionTypes.ERROR_HANDLED:
            let errorId = action.id;
            let handledError = null;

            if(newState.currentError && newState.currentError.id === errorId){
                handledError = newState.currentError;
            }
            else{
                handledError = newState.historyErrors.find((item) => {
                    return item.id === errorId;
                });
            }

            if(handledError) handledError.isHandled = true;

            return newState;
        case actionTypes.ERROR:
            let error = action.error;

            if(newState.currentError){
                newState.historyErrors.push(newState.currentError);
                newState.historyErrors.length = Math.min(newState.historyErrors.length, 20);
            }

            newState.currentError = action.error;

            return newState;
        default:
            return state;
    }
}