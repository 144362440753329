import { useSelector } from "react-redux";

const ProtectedComponent = ({ children, nonAuthComponent = null }) => {
    const user = useSelector((state) => state.user);

    if (user?.access_token) {
        return children || null;
    }

    return nonAuthComponent;
};

export default ProtectedComponent;
