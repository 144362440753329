import React from 'react';

class LazyLoadedComponent extends React.Component {
  constructor(props) {
    super(props);

    this.triggers = {
        load: false,
        observer: true,
        ...props.triggers
    }

    this.style = {};
    if (props.width) {
        this.style.width = props.width;
    }
    if (props.height) {
        this.style.height = props.height;
    }

    this.state = {
      loadedComponent: null
    };
    this.componentRef = React.createRef();
    this.hasLoaded = false;
    this.loadComponent = this.loadComponent.bind(this);
  }

  componentDidMount() {
    if (this.triggers.observer) {
        this.observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    this.loadComponent();
                    this.observer.unobserve(entry.target);
                }
            },
            { threshold: 0.1 }
        );

        if (this.componentRef.current) {
            this.observer.observe(this.componentRef.current);
        }
    }

    if (this.triggers.load) {
        window.addEventListener('load', () => setTimeout(this.loadComponent, 1000));
    }

    if (!this.triggers.observer && !this.triggers.load) {
        this.loadComponent();
    }
  }

  componentWillUnmount() {
    if (this.componentRef.current && this.observer && this.triggers.observer) {
      this.observer.unobserve(this.componentRef.current);
    }

    if (this.triggers.load) {
        window.removeEventListener('load', () => setTimeout(this.loadComponent, 1000));
    }
  }

  loadComponent = async () => {
    if (!this.hasLoaded) {
      this.hasLoaded = true;
      try {
        const component = await this.props.load();
        this.setState({ loadedComponent: component.default });
      } catch (error) {
        console.error('Failed to load component:', error);
      }
    }
  }

  render() {
    const { loading, children } = this.props;
    const { loadedComponent } = this.state;

    const style = !loadedComponent ? this.style : {};

    return (
      <div ref={this.componentRef} style={style}>
        {loadedComponent ? children(loadedComponent) : loading}
      </div>
    );
  }
}

export default LazyLoadedComponent;