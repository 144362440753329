import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useParams,
} from "wouter-preact";

import * as dispatches from "../../../storage/dispatches.js";

import UserHeaderView from "./UserHeaderViewDesktop.jsx";
import UserHeaderViewMobile from "./mobile/UserHeaderViewMobile.jsx";
import * as UIController from "../../app/UIController.jsx";
import { useHistory, useLocation } from "../../../hooks/router.js";

const UserHeader = ({ isActiveHeader }) => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const user = useSelector((state) => state.user);
    const isMobile = useSelector((state) => state.app.isMobile);
    const stats = useSelector((state) => state.app.stats);
    const dispatch = useDispatch();

    const onLogout = useCallback(() => dispatch(dispatches.userLogout()), []);

    const productItemBoxList = useSelector((state) => state.product.productItemBoxList);
    const boxItem = useSelector((state) => state.box.boxItem);

    const burgerClickHandler = useCallback(() =>
        UIController.mobileMenu.show(location, history)
    ,[location, history]);

    const box = useMemo(() => {
        if (boxItem) {
            return boxItem;
        }

        return (
            productItemBoxList?.find((box) => box.name_id === params.box_name_id) ||
            productItemBoxList?.[0]
        );
    }, [productItemBoxList, boxItem]);

    return isMobile ? (
        <UserHeaderViewMobile
            user={user}
            isActiveHeader={isActiveHeader}
            onBurgerClick={burgerClickHandler}
            stats={stats}
            box={box}
        />
    ) : (
        <UserHeaderView
            user={user}
            onLogout={onLogout}
            stats={stats}
            isActiveHeader={isActiveHeader}
            box={box}
        />
    );
};

export default UserHeader;
