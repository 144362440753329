import React from "react";
import { Link } from "wouter-preact";

import "./banner.css";

import { Slider } from "../slider/index.jsx";

import bannerSlideOne from "../../img/banner/bannerDesktopSlideOne.webp";
import bannerSlideTwo from "../../img/banner/bannerDesktopSlideTwo.webp";
import bannerSlideThree from "../../img/banner/bannerDesktopSlideThree.webp";
import bannerSlideFour from "../../img/banner/bannerDesktopSlideFour.webp";
import bannerSlideFive from "../../img/banner/bannerDesktopSlideFive.webp";
import getStartedBtn from "../../img/banner/bannerGetStartedBtn.svg";
import howItWorksBtn from "../../img/banner/bannerHowItWorksBtn.svg";
import clickClickWin from "../../img/banner/clickClickWin.svg";

import MainButton from "../button/mainButton/MainButton.jsx";

const BannerView = ({ onOpenBox }) => {
    return (
        <div className="banner-wrap">
            <div className="banner-content-wrap">
                <div className="banner-bg-wrap">
                    <Slider config={{
                        all: {
                            slideGap: '0px',
                            slidesToShow: 1,
                            enableAutoplay: true,
                            autoplayInterval: 7000,
                        },
                    }} arrows={true}>
                        <div>
                            <div className="banner-img-wrap">
                                <img src={bannerSlideOne} alt="Banner" /> 
                            </div>
                            <div className="banner-center">
                                <div className="banner-header">
                                    <span className="banner-header-title">
                                        Unbox Your Dreams:
                                        <br /> BIG WIN EACH 5th
                                    </span>
                                </div>
                                <div className="banner-buttons">
                                    <div className="banner-btn">
                                        <Link to="/box/surprise-box/">
                                            <img
                                                src={getStartedBtn}
                                                alt="Get Started"
                                            />
                                        </Link>
                                    </div>
                                    <div className="banner-btn">
                                        <a href="/#main-how-it-works-block">
                                            <img
                                                src={howItWorksBtn}
                                                alt="How It Works"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-info">
                                <div className="banner-info-headline">
                                    <Link
                                        to={
                                            "/product/louis-vuitton-discovery-pm-backpack/"
                                        }
                                    >
                                        <div className="banner-info-title-wrap">
                                            LOUIS VUITTON Discovery PM Backpack
                                        </div>
                                    </Link>
                                </div>
                                <div className="banner-info-body">
                                    <div className="banner-info-action-wrap">
                                        <MainButton
                                            text={"WIN FOR ₦1500"}
                                            onClickHandler={() => onOpenBox(0)}
                                        />
                                    </div>
                                    <Link
                                        to={
                                            "/product/louis-vuitton-discovery-pm-backpack/"
                                        }
                                    >
                                        <div className="banner-info-price-wrap">
                                            Price: ₦1945816
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="banner-img-wrap">
                                <img loading="lazy" src={bannerSlideTwo} alt="Banner" />
                            </div>
                            <div className="banner-center">
                                <div className="banner-header">
                                    <span className="banner-header-title">
                                        CHOOSE IT WISE
                                        <br /> GRAB YOUR PRIZE
                                    </span>
                                </div>
                                <div className="banner-buttons">
                                    <div className="banner-btn">
                                        <Link to="/box/surprise-box/">
                                            <img
                                                src={getStartedBtn}
                                                alt="Get Started"
                                            />
                                        </Link>
                                    </div>
                                    <div className="banner-btn">
                                        <a href="/#main-how-it-works-block">
                                            <img
                                                src={howItWorksBtn}
                                                alt="How It Works"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-info">
                                <div className="banner-info-headline">
                                    <Link
                                        to={
                                            "/product/iphone-14-pro-256-gb---deep-purple/"
                                        }
                                    >
                                        <div className="banner-info-title-wrap">
                                            Apple iPhone 15 Pro 256GB Blue
                                            Titanium
                                        </div>
                                    </Link>
                                </div>
                                <div className="banner-info-body">
                                    <div className="banner-info-action-wrap">
                                        <MainButton
                                            text={"WIN FOR ₦2000"}
                                            onClickHandler={() => onOpenBox(1)}
                                        />
                                    </div>
                                    <Link
                                        to={
                                            "/product/iphone-14-pro-256-gb---deep-purple/"
                                        }
                                    >
                                        <div className="banner-info-price-wrap">
                                            {" "}
                                            Price: ₦1650000{" "}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="banner-img-wrap">
                                <img loading="lazy" src={bannerSlideThree} alt="Banner" />
                            </div>
                            <div className="banner-center">
                                <div className="banner-header">
                                    <span className="banner-header-title">
                                        Every single click
                                        <br /> chance to WIN BIG!
                                    </span>
                                </div>
                                <div className="banner-buttons">
                                    <div className="banner-btn">
                                        <Link to="/box/surprise-box/">
                                            <img
                                                src={getStartedBtn}
                                                alt="Get Started"
                                            />
                                        </Link>
                                    </div>
                                    <div className="banner-btn">
                                        <a href="/#main-how-it-works-block">
                                            <img
                                                src={howItWorksBtn}
                                                alt="How It Works"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-info">
                                <div className="banner-info-headline">
                                    <Link to={"/product/apple-imac-pro-27/"}>
                                        <div className="banner-info-title-wrap">
                                            Apple iMac Pro 27
                                        </div>
                                    </Link>
                                </div>
                                <div className="banner-info-body">
                                    <div className="banner-info-action-wrap">
                                        <MainButton
                                            text={"WIN FOR ₦3000"}
                                            onClickHandler={() => onOpenBox(2)}
                                        />
                                    </div>
                                    <Link to={"/product/apple-imac-pro-27/"}>
                                        <div className="banner-info-price-wrap">
                                            Price: ₦1270554
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="banner-img-wrap">
                                <img loading="lazy" src={bannerSlideFour} alt="Banner" />
                            </div>
                            <div className="banner-center">
                                <div className="banner-header">
                                    <span className="banner-header-title">
                                        <img src={clickClickWin} alt="Click" />
                                    </span>
                                </div>
                                <div className="banner-buttons">
                                    <div className="banner-btn">
                                        <Link to="/box/surprise-box/">
                                            <img
                                                src={getStartedBtn}
                                                alt="Get Started"
                                            />
                                        </Link>
                                    </div>
                                    <div className="banner-btn">
                                        <a href="/#main-how-it-works-block">
                                            <img
                                                src={howItWorksBtn}
                                                alt="How It Works"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-info">
                                <div className="banner-info-headline">
                                    <Link
                                        to={"/product/audi-a5-sportback-2020/"}
                                    >
                                        <div className="banner-info-title-wrap">
                                            Audi A5 Sportback 2020
                                        </div>
                                    </Link>
                                </div>
                                <div className="banner-info-body">
                                    <div className="banner-info-action-wrap">
                                        <MainButton
                                            text={"WIN FOR ₦20000"}
                                            onClickHandler={() => onOpenBox(3)}
                                        />
                                    </div>
                                    <Link
                                        to={"/product/audi-a5-sportback-2020/"}
                                    >
                                        <div className="banner-info-price-wrap">
                                            Price: ₦39992352
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="banner-img-wrap">
                                <img loading="lazy" src={bannerSlideFive} alt="Banner" />
                            </div>
                            <div className="banner-center">
                                <div className="banner-header">
                                    <span className="banner-header-title">
                                        RARE DROPS
                                        <br /> IN THE BOX
                                    </span>
                                </div>
                                <div className="banner-buttons">
                                    <div className="banner-btn">
                                        <Link to="/box/surprise-box/">
                                            <img
                                                src={getStartedBtn}
                                                alt="Get Started"
                                            />
                                        </Link>
                                    </div>
                                    <div className="banner-btn">
                                        <a href="/#main-how-it-works-block">
                                            <img
                                                src={howItWorksBtn}
                                                alt="How It Works"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-info">
                                <div className="banner-info-headline">
                                    <Link
                                        to={
                                            "/product/louis-vuitton-sneakers-designed-by-virgil-abloh-blue/"
                                        }
                                    >
                                        <div className="banner-info-title-wrap">
                                            LOUIS VUITTON SNEAKERS BY VIRGIL
                                            ABLOH BLUE
                                        </div>
                                    </Link>
                                </div>
                                <div className="banner-info-body">
                                    <div className="banner-info-action-wrap">
                                        <MainButton
                                            text={"WIN FOR ₦1500"}
                                            onClickHandler={() => onOpenBox(4)}
                                        />
                                    </div>
                                    <Link
                                        to={
                                            "/product/louis-vuitton-sneakers-designed-by-virgil-abloh-blue/"
                                        }
                                    >
                                        <div className="banner-info-price-wrap">
                                            Price: ₦969751
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default BannerView;
