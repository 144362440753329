import instagram from '../../svg/new/instagram.svg';
import facebook from '../../svg/new/facebook.svg';
import x from '../../svg/new/x.svg';
import tiktok from '../../svg/new/tiktok.svg';

const links = [
  {
    icon: instagram,
    link: 'https://www.instagram.com/7uckybox',
  },
  {
    icon: facebook,
    link: 'https://www.facebook.com/7uckybox',
  },
  {
    icon: x,
    link: 'https://x.com/7uckybox',
  },
  {
    icon: tiktok,
    link: 'https://www.tiktok.com/@7uckybox',
  },
];

export default links;
