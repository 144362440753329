import * as actionTypes from "../actionTypes";

let initialState = {
    globalPreloader: null,
    popupSignup: null,
    popupLogin: null,
    popup: null,
    mobileMenuVisibility: false,
    phone: {
        prefix: '',
        main: ''
    }
};

export default function ui(state = initialState, action) {
    // console.log('[UI.REDUCER.ACTION]:', action);

    let newState = {...state};

    switch (action.type) {
        case actionTypes.GLOBAL_PRELOADER_SHOW:
            newState.globalPreloader = action.preloader;

            return newState;
        case actionTypes.GLOBAL_PRELOADER_HIDE:
            newState.globalPreloader = null;

            return newState;
        case actionTypes.POPUP_LOGIN_SHOW:
            newState.popupSignup = null;
			newState.popupPayment = null;
            newState.popupLogin = action.popup;

            return newState;
        case actionTypes.POPUP_SIGNUP_SHOW:
            newState.popupLogin = null;
			newState.popupPayment = null;
            newState.popupSignup = action.popup;

			return newState;
		case actionTypes.POPUP_PAYMENT_SHOW:
			newState.popupLogin = null;
			newState.popupSignup = null;
			newState.popupPayment = action.popup;

            return newState;
        case actionTypes.POPUP_LOGIN_HIDE:
            newState.popupLogin = null;

            return newState;
        case actionTypes.POPUP_SIGNUP_HIDE:
            newState.popupSignup = null;

            return newState;
		case actionTypes.POPUP_PAYMENT_HIDE:
			newState.popupPayment = null;

			return newState;
        case actionTypes.POPUP_SHOW:
            newState.popupLogin = null;
            newState.popupSignup = null;
            newState.popup = action.popup;

            return newState;
        case actionTypes.POPUP_HIDE:
            newState.popup = null;

            return newState;
        case actionTypes.PHONE_FORM_SET:
            newState.phone = action.phone;

            return newState;
        default:
            return state
    }
}