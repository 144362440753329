import * as CONST from './QueryConstants';

class ServerAPIQuery {
    query = {};
    localParams = {};

    queryBuilder (key, params = {}, localParams = {}){
        this.query[key] = params;
        this.localParams[key] = localParams;

        return this;
    }

    // USER

    userInfo(params){
        return this.queryBuilder(CONST.USER_INFO, params);
    }

    userTransactions(params, localParams){
        return this.queryBuilder(CONST.USER_TRANSACTIONS, params, localParams);
    }

    userProfile(params){
        return this.queryBuilder(CONST.USER_PROFILE, params);
    }

    userProfileUpdate(params){
        return this.queryBuilder(CONST.USER_PROFILE_UPDATE, params);
    }

    userProfilePasswordUpdate(params){
        return this.queryBuilder(CONST.USER_PROFILE_PASSWORD_UPDATE, params);
    }

    userProductList(params, localParams){
        return this.queryBuilder(CONST.USER_PRODUCT_LIST, params, localParams);
    }

    userProductSell(params){
        return this.queryBuilder(CONST.USER_PRODUCT_SELL, params);
    }

    userAddressList(params){
        return this.queryBuilder(CONST.USER_ADDRESS_LIST, params);
    }

    userAddressInfo(params){
        return this.queryBuilder(CONST.USER_ADDRESS_INFO, params);
    }

    userAddressAdd(params){
        return this.queryBuilder(CONST.USER_ADDRESS_ADD, params);
    }

    userAddressUpdate(params){
        return this.queryBuilder(CONST.USER_ADDRESS_UPDATE, params);
    }

    userAddressRemove(params){
        return this.queryBuilder(CONST.USER_ADDRESS_REMOVE, params);
    }

    userProductDelivery(params){
        return this.queryBuilder(CONST.USER_PRODUCT_DELIVERY, params);
    }

    userSeedReveal(params){
        return this.queryBuilder(CONST.USER_SEED_REVEAL, params);
    }

    userSeedList(params){
        return this.queryBuilder(CONST.USER_SEED_LIST, params);
    }

    userReplenish(params){
        return this.queryBuilder(CONST.USER_REPLENISH, params);
    }

    // BOXES

    allBox(params){
        return this.queryBuilder(CONST.BOX_ALL, params);
    }

    openBox(params){
        return this.queryBuilder(CONST.BOX_OPEN, params);
    }

    boxInfo (params){
        return this.queryBuilder(CONST.BOX_INFO, params);
    }

    boxProducts (params){
        return this.queryBuilder(CONST.BOX_PRODUCTS, params);
    }

    boxRecommended (params){
        return this.queryBuilder(CONST.BOX_RECOMMENDED, params);
    }

    boxWithProduct (params){
        return this.queryBuilder(CONST.BOX_WITH_PRODUCT, params);
    }

    // PRODUCTS

    productInfo (params){
        return this.queryBuilder(CONST.PRODUCT_INFO, params);
    }

    productRecommended (params){
        return this.queryBuilder(CONST.PRODUCT_RECOMMENDED, params);
    }

    // CATEGORIES

    categoryBoxList(params){
        return this.queryBuilder(CONST.CATEGORY_BOX_LIST, params);
    }

    categoryBoxInfo(params){
        return this.queryBuilder(CONST.CATEGORY_BOX_INFO, params);
    }

    categoryBoxBoxes(params){
        return this.queryBuilder(CONST.CATEGORY_BOX_BOXES, params);
    }

    // LANGUAGES

    languageList(params){
        return this.queryBuilder(CONST.LANGUAGE_LIST, params);
    }

    languageKeyList(params){
        return this.queryBuilder(CONST.LANGUAGE_KEY_LIST, params);
    }

    // OTHERS

    stats(params){
        return this.queryBuilder(CONST.STATS, params);
    }

    lastWinners(params){
        return this.queryBuilder(CONST.LAST_WINNERS, params);
    }

    phoneList(params){
        return this.queryBuilder(CONST.PHONE_LIST, params);
    }

    addressList(params){
        return this.queryBuilder(CONST.ADDRESS_LIST, params);
    }

    addressChildren(params){
        return this.queryBuilder(CONST.ADDRESS_CHILDREN, params);
    }

    countryList(params){
        return this.queryBuilder(CONST.COUNTRY_LIST, params);
    }
    //

    json (){
        return JSON.stringify(this.query);
    }
}

export default ServerAPIQuery;