import * as actionTypes from "../actionTypes";

let initialState = {
    productItem: null,
    productItemBoxList: [],
    productRecommendedList: []
};

export default function product(state = initialState, action) {
    // console.log('[PRODUCT.REDUCER.ACTION]:', action);

    let newState = {...state};

    switch (action.type) {
        case actionTypes.PRODUCT_ITEM_SET:
            newState.productItem = action.product;

            return newState;
        case actionTypes.PRODUCT_ITEM_BOXES_SET:
            newState.productItemBoxList = action.products;

            return newState;
        case actionTypes.PRODUCT_RECOMMENDED_LIST_SET:
            newState.productRecommendedList = action.products;

            return newState;
        default:
            return state
    }
}