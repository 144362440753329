import React, {Component} from 'react';
import BasePopup from '../_basePopup/BasePopup.jsx';

import './views.css';
import Eye from '../../components/userHeader/eye.jsx';

class PopupViews extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <BasePopup onClickBackground={this.props.onClickBackground} isNewClose>
                <div className="product-popup-content popup-views" onClick={e => e.stopPropagation()}>
                    <div className="popup-views-eye">
                        <Eye />
                    </div>
                    <div className="popup-views-num">{this.props.num}</div>
                    <div className="popup-views-text">{this.props.text}</div>
                </div>
            </BasePopup>
        );
    }
}

export default PopupViews;
