export const BOX_LIST_SET = 'BOX_LIST_SET';
export const BOX_ITEM_SET = 'BOX_ITEM_SET';
export const BOX_ITEM_PRODUCTS_SET = 'BOX_ITEM_PRODUCTS_SET';
export const BOX_RECOMMENDED_LIST_SET = 'BOX_RECOMMENDED_LIST_SET';
export const BOX_TO_OPEN_SET = 'BOX_TO_OPEN_SET';

// export const PRODUCT_LIST_SET = 'PRODUCT_LIST_SET';
export const PRODUCT_ITEM_BOXES_SET = 'PRODUCT_ITEM_BOXES_SET';
export const PRODUCT_ITEM_SET = 'PRODUCT_ITEM_SET';
export const PRODUCT_RECOMMENDED_LIST_SET = 'PRODUCT_RECOMMENDED_LIST_SET';

export const USER_SET_DATA = 'USER_SET_DATA';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_PRODUCT_LIST_SET = 'USER_PRODUCT_LIST_SET';
export const USER_PRODUCT_LIST_ADD = 'USER_PRODUCT_LIST_ADD';
export const USER_PRODUCT_SELL = 'USER_PRODUCT_SELL';
export const USER_PRODUCT_DELIVERY = 'USER_PRODUCT_DELIVERY';
export const USER_ADDRESS_LIST_SET = 'USER_ADDRESS_LIST_SET';
export const USER_ADDRESS_INFO_SET = 'USER_ADDRESS_INFO_SET';
export const USER_ADDRESS_ADD = 'USER_ADDRESS_ADD';
export const USER_ADDRESS_REMOVE = 'USER_ADDRESS_REMOVE';
export const USER_ADDRESS_UPDATE = 'USER_ADDRESS_UPDATE';
export const USER_TRANSACTIONS_SET = 'USER_TRANSACTIONS_SET';
export const USER_TRANSACTIONS_ADD = 'USER_TRANSACTIONS_ADD';
export const USER_SEED_LIST_SET = 'USER_SEED_LIST_SET';
export const USER_REPLENISH_SET = 'USER_REPLENISH_SET';

export const POPUP_SHOW = 'POPUP_SHOW';
export const POPUP_HIDE = 'POPUP_HIDE';
export const POPUP_LOGIN_SHOW = 'POPUP_LOGIN_SHOW';
export const POPUP_LOGIN_HIDE = 'POPUP_LOGIN_HIDE';
export const POPUP_SIGNUP_SHOW = 'POPUP_SIGNUP_SHOW';
export const POPUP_SIGNUP_HIDE = 'POPUP_SIGNUP_HIDE';
export const POPUP_PAYMENT_SHOW = 'POPUP_PAYMENT_SHOW';
export const POPUP_PAYMENT_HIDE = 'POPUP_PAYMENT_HIDE';

export const GLOBAL_PRELOADER_SHOW = 'GLOBAL_PRELOADER_SHOW';
export const GLOBAL_PRELOADER_HIDE = 'GLOBAL_PRELOADER_HIDE';

export const CATEGORY_SET_LIST = 'CATEGORY_SET_LIST';

export const STATS_SET = 'STATS_SET';

export const LAST_WINNERS_SET = 'LAST_WINNERS_SET';

export const SPINNER_SET = 'SPINNER_SET';
export const SPINNER_STATUS_SET = 'SPINNER_STATUS_SET';

export const LANGUAGE_CURRENT_SET = 'LANGUAGE_CURRENT_SET';
export const LANGUAGE_LIST_SET = 'LANGUAGE_LIST_SET';
export const LANGUAGE_KEY_LIST_SET = 'LANGUAGE_KEY_LIST_SET';
export const COUNTRY_LIST = 'COUNTRY_LIST';

export const CURRENCY_CURRENT_SET = 'CURRENCY_CURRENT_SET';

// export const PRIZE_LIST_SET = 'PRIZE_LIST_SET';
// export const PRIZE_CURRENT_SET = 'PRIZE_CURRENT_SET';

export const PRIZE_LIST_ADD_ITEM = 'PRIZE_LIST_ADD_ITEM';
export const PRIZE_LIST_MARK_SOLD_ITEM = 'PRIZE_LIST_MARK_SOLD_ITEM';

export const PRIZE_LIST_FREE_ADD_ITEM = 'PRIZE_LIST_FREE_ADD_ITEM';


// MOBILE
export const IS_MOBILE_VERSION_SET = 'IS_MOBILE_VERSION_SET';

// ERROR

export const ERROR = "ERROR";
export const ERROR_HANDLED = "ERROR_HANDLED";

// PHONE LIST

export const PHONE_LIST_SET = 'PHONE_LIST_SET';
export const PHONE_FORM_SET = 'PHONE_FORM_SET';

// ALERT

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';