import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import BannerView from "./BannerViewDesktop.jsx";
import BannerViewMobile from "./mobile/BannerViewMobile.jsx";
import { handleOpenBox } from "../../app/UIController.jsx";
import { useHistory } from "../../../hooks/router.js";

//const BannerView = React.lazy(() => import("./BannerViewDesktop.jsx"));
//const BannerViewMobile = React.lazy(() => import("./mobile/BannerViewMobile.jsx"));

const boxes = [
    {
        name_id: "mens-accessories",
        price: 1500,
        title: "Womens Accessories",
    },
    {
        name_id: "phones-and-tablets",
        price: 2000,
        title: "Phones and Tablets",
    },
    {
        name_id: "computers",
        price: 3000,
        title: "Computers",
    },
    {
        name_id: "vehicles-",
        price: 20000,
        title: "Vehicles",
    },
    {
        name_id: "mens-accessories",
        price: 1500,
        title: "Mens Accessories",
    },
];

const Banner = () => {
    const history = useHistory();

    const isMobile = useSelector(state => state.app.isMobile);

    const openBoxHandler = useCallback((index) => {
        const box = boxes[index];

        handleOpenBox(box, history, true);
    }, [history]);

    return isMobile ? (
        <BannerViewMobile onOpenBox={openBoxHandler} />
    ) : (
        <BannerView onOpenBox={openBoxHandler} />
    );
};

export default React.memo(Banner);
