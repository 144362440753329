import React, { useEffect, useMemo, useRef } from "react";

const Lottie = ({ loadAnimation, alt, options = {}, fallback, className, width }) => {
    const containerRef = useRef(null);

    const style = useMemo(() => {
        const _style = {}
        if (width) {
            _style.width = `${width}px`;
        }

        return _style;
    })

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        container.innerHTML = `<img src="${fallback}" alt=${alt} />`;

        setTimeout(() => {
            Promise.all([import("lottie-web"), loadAnimation()]).then(
                ([{ default: lottie }, { default: animationData }]) => {
                    const logoLottieOptions = {
                        container,
                        animationData,
                        ...options,
                    };
    
                    container.innerHTML = "";
                    lottie.loadAnimation(logoLottieOptions);
                }
            );
        }, 0);
    }, []);

    return (
        <div style={style} ref={containerRef} className={className}></div>
    );
};

export default Lottie;
