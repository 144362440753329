import React from 'react';
import BasePopup from "../_basePopup/BasePopup.jsx";

import './popupTitle.css'

function getContentHtml (cilckHandler, colorType, children, title, cls = undefined){
	return (
		<BasePopup onClickBackground={cilckHandler}>
			<div className={`popup-title ${cls ? cls : ''}`}>
				<div className={`popup-title-block`}>
					<div className="popup-title-text">{title}</div>
				</div>
				<div className="popup-title-content">
					{children}
				</div>
			</div>
		</BasePopup>
	);
}

const PopupTitle = (props) => (
    <>
        {getContentHtml(props.onClickBackground, "orange", props.children, props.title, props.class)}
    </>
)

const PopupTitleBlue = (props) => (
    <>
        {getContentHtml(props.onClickBackground, "blue", props.children, props.title)}
    </>
)

export {PopupTitle, PopupTitleBlue};
