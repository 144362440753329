import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import FooterView from "./FooterView.jsx";
import FooterMobileView from "./mobile/FooterMobileView.jsx";
import i18n from "../../../i18n/i18n";

const Footer = () => {
    const isMobile = useSelector((state) => state.app.isMobile)

    const navLinks = useMemo(() => {
        return {
            mainLinks: [
                { url: '/#main-boxes-block', text: i18n.get('button.text.boxes') },
                { url: '/#main-how-it-works-block', text: `${i18n.get('how.it.works')} / ${i18n.get('faq')}` },
                { url: '/#main-reviews-block', text: i18n.get('reviews') },
                { url: '/#main-win-stats-block', text: i18n.get('button.text.win.stats') },
                { url: '/#main-about-us-block', text: i18n.get('text.about.us') },
                { url: '/#main-contact-us-block', text: i18n.get('text.contact.us') },
            ],
            informationLinks: [
                { url: '/terms/', text: i18n.get('link.terms.conditions') },
                { url: '/delivery/', text: i18n.get('text.delivery') },
                { url: '/returns/', text: i18n.get('text.returns.purchase') },
                { url: '/privacy/', text: i18n.get('text.privacy.policy') },
            ],
        };
    });

    return (
        <>
            {isMobile ? (
                <FooterMobileView
                    mainLinks={navLinks.mainLinks}
                    informationLinks={navLinks.informationLinks}
                />
            ) : (
                <FooterView
                    mainLinks={navLinks.mainLinks}
                    informationLinks={navLinks.informationLinks}
                />
            )}
        </>
    );
};

export default Footer;
