import React, { useMemo } from "react";
import { useParams } from "wouter-preact";
import { useHistory, useLocation } from "./router";

export function withRouter(Component) {
    const ComponentWithRouterProp = (props) => {
        const location = useLocation();
        const params = useParams();

        const history = useHistory();
        const match = useMemo(() => ({ params }), [params])

        return (
            <Component
                {...props}
                match={match}
                location={location}
                history={history}
            />
        );
    }

    ComponentWithRouterProp.displayName = `WithRouter(${Component.displayName || Component.name || 'Component'})`;

    return ComponentWithRouterProp;
}
