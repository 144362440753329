import React, { useEffect, useMemo, useState } from "react";
import { Link } from "wouter-preact";

import { Slider } from "../../slider/index.jsx";

import "./bannerMobile.css";

import getStartedBtn from "../../../img/banner/bannerGetStartedBtn.svg?inline";
import howItWorksBtn from "../../../img/banner/bannerHowItWorksBtn.svg";
import bannerSlideOne from "../../../img/banner/bannerMobileSlideOne.webp";
import bannerSlideOne2x from "../../../img/banner/bannerMobileSlideOne2x.webp";
import bannerSlideTwo from "../../../img/banner/bannerMobileSlideTwo.webp";
import bannerSlideThree from "../../../img/banner/bannerMobileSlideThree.webp";
import bannerSlideFour from "../../../img/banner/bannerMobileSlideFour.webp";
import bannerSlideFive from "../../../img/banner/bannerMobileSlideFive.webp";
import clickClickWin from "../../../img/banner/clickClickWin.svg";

import MainButton from "../../button/mainButton/MainButton.jsx";

const BannerView = ({ onOpenBox }) => {
    const [startAutoplay, setStartAutoplay] = useState(false);

    useEffect(() => {
        if (navigator.userAgent.includes('moto g power')) {
            return;
        }
        
        setStartAutoplay(true);
    }, []);

    const config = useMemo(() => {
        return {
            all: {
                enableAutoplay: startAutoplay,
                autoplayInterval: 7000,
                slidesToShow: 1,
            },
        };
    }, [startAutoplay]);

    return (
        <div className="m-banner-wrap">
            <div className="m-banner-content-wrap">
                <div className="m-banner-bg-wrap">
                    <Slider config={config} arrows={true}>
                        <div>
                            <div className="m-banner-header">
                                <span className="m-banner-header-title">
                                    Unbox Your Dreams:
                                    <br /> BIG WIN EACH 5th
                                </span>
                            </div>
                            <div className="m-banner-img-wrap">
                                <img
                                    style={{ 'margin-left': '25px' }}
                                    width="301"
                                    height="312"
                                    src={bannerSlideOne}
                                    srcSet={`${bannerSlideOne2x} 2x`}
                                    alt="Banner"
                                />
                                <div className="m-banner-info">
                                    <div className="m-banner-info-headline">
                                        <Link
                                            to={
                                                "/product/louis-vuitton-discovery-pm-backpack/"
                                            }
                                        >
                                            <div className="m-banner-info-title-wrap">
                                                LOUIS VUITTON Backpack
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="m-banner-info-body">
                                        <div className="m-banner-info-action-wrap">
                                            <MainButton
                                                text={"WIN FOR ₦1500"}
                                                onClickHandler={() =>
                                                    onOpenBox(0)
                                                }
                                            />
                                        </div>
                                        <Link
                                            to={
                                                "/product/louis-vuitton-discovery-pm-backpack/"
                                            }
                                        >
                                            <div className="m-banner-info-price-wrap">
                                                Price: ₦1945816
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="m-banner-buttons">
                                <div className="m-banner-btn">
                                    <a href="/box/surprise-box/">
                                        <img
                                            src={getStartedBtn}
                                            alt="Get Started"
                                        />
                                    </a>
                                </div>
                                <div className="m-banner-btn">
                                    <a href="/#main-how-it-works-block">
                                        <img
                                            src={howItWorksBtn}
                                            alt="How It Works"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        { startAutoplay &&
                        <>
                        <div>
                            <div className="m-banner-header">
                                <span className="m-banner-header-title">
                                    CHOOSE IT WISE
                                    <br /> GRAB YOUR PRIZE
                                </span>
                            </div>
                            <div className="m-banner-img-wrap">
                                <img
                                    style={{ 'margin-left': '30px' }}
                                    loading="lazy"
                                    src={bannerSlideTwo}
                                    alt="Banner"
                                />
                                <div className="m-banner-info">
                                    <div className="m-banner-info-headline">
                                        <Link
                                            to={
                                                "/product/iphone-14-pro-256-gb---deep-purple/"
                                            }
                                        >
                                            <div className="m-banner-info-title-wrap">
                                                Apple iPhone 15 Pro 256GB
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="m-banner-info-body">
                                        <div className="m-banner-info-action-wrap">
                                            <MainButton
                                                text={"WIN FOR ₦2000"}
                                                onClickHandler={() =>
                                                    onOpenBox(1)
                                                }
                                            />
                                        </div>
                                        <Link
                                            to={
                                                "/product/iphone-14-pro-256-gb---deep-purple/"
                                            }
                                        >
                                            <div className="m-banner-info-price-wrap">
                                                Price: ₦1650000
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="m-banner-buttons">
                                <div className="m-banner-btn">
                                    <a href="/box/surprise-box/">
                                        <img
                                            src={getStartedBtn}
                                            alt="Get Started"
                                        />
                                    </a>
                                </div>
                                <div className="m-banner-btn">
                                    <a href="/#main-how-it-works-block">
                                        <img
                                            src={howItWorksBtn}
                                            alt="How It Works"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="m-banner-header">
                                <span className="m-banner-header-title">
                                    Every single
                                    <br /> click chance
                                    <br /> to WIN BIG!
                                </span>
                            </div>
                            <div className="m-banner-img-wrap">
                                <img
                                    style={{ 'margin-left': '25px' }}
                                    loading="lazy"
                                    src={bannerSlideThree}
                                    alt="Banner"
                                />
                                <div className="m-banner-info">
                                    <div className="m-banner-info-headline">
                                        <Link
                                            to={"/product/apple-imac-pro-27/"}
                                        >
                                            <div className="m-banner-info-title-wrap">
                                                Apple iMac Pro 27
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="m-banner-info-body">
                                        <div className="m-banner-info-action-wrap">
                                            <MainButton
                                                text={"WIN FOR ₦3000"}
                                                onClickHandler={() =>
                                                    onOpenBox(2)
                                                }
                                            />
                                        </div>
                                        <Link
                                            to={"/product/apple-imac-pro-27/"}
                                        >
                                            <div className="m-banner-info-price-wrap">
                                                Price: ₦1270554
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="m-banner-buttons">
                                <div className="m-banner-btn">
                                    <a href="/box/surprise-box/">
                                        <img
                                            src={getStartedBtn}
                                            alt="Get Started"
                                        />
                                    </a>
                                </div>
                                <div className="m-banner-btn">
                                    <a href="/#main-how-it-works-block">
                                        <img
                                            src={howItWorksBtn}
                                            alt="How It Works"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="m-banner-header">
                                <span className="m-banner-header-title">
                                    <img
                                        loading="lazy"
                                        src={clickClickWin}
                                        alt="Click"
                                    />
                                </span>
                            </div>
                            <div className="m-banner-img-wrap">
                                <img
                                    loading="lazy"
                                    src={bannerSlideFour}
                                    alt="Banner"
                                />
                                <div className="m-banner-info">
                                    <div className="m-banner-info-headline">
                                        <Link
                                            to={
                                                "/product/audi-a5-sportback-2020/"
                                            }
                                        >
                                            <div className="m-banner-info-title-wrap">
                                                Audi A5 Sportback 2020
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="m-banner-info-body">
                                        <div className="m-banner-info-action-wrap">
                                            <MainButton
                                                text={"WIN FOR ₦20000"}
                                                onClickHandler={() =>
                                                    onOpenBox(3)
                                                }
                                            />
                                        </div>
                                        <Link
                                            to={
                                                "/product/audi-a5-sportback-2020/"
                                            }
                                        >
                                            <div className="m-banner-info-price-wrap">
                                                Price: ₦39992352
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="m-banner-buttons">
                                <div className="m-banner-btn">
                                    <a href="/box/surprise-box/">
                                        <img
                                            src={getStartedBtn}
                                            alt="Get Started"
                                        />
                                    </a>
                                </div>
                                <div className="m-banner-btn">
                                    <a href="/#main-how-it-works-block">
                                        <img
                                            src={howItWorksBtn}
                                            alt="How It Works"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="m-banner-header">
                                <span className="m-banner-header-title">
                                    RARE DROPS
                                    <br /> IN THE BOX
                                </span>
                            </div>

                            <div className="m-banner-img-wrap">
                                <img
                                    loading="lazy"
                                    src={bannerSlideFive}
                                    alt="Banner"
                                />
                                <div className="m-banner-info">
                                    <div className="m-banner-info-headline">
                                        <Link
                                            to={
                                                "/product/louis-vuitton-sneakers-designed-by-virgil-abloh-blue/"
                                            }
                                        >
                                            <div className="m-banner-info-title-wrap">
                                                LOUIS VUITTON SNEAKERS
                                                <br /> BY VIRGIL ABLOH BLUE
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="m-banner-info-body">
                                        <div className="m-banner-info-action-wrap">
                                            <MainButton
                                                text={"WIN FOR ₦1500"}
                                                onClickHandler={() =>
                                                    onOpenBox(4)
                                                }
                                            />
                                        </div>
                                        <Link
                                            to={
                                                "/product/louis-vuitton-sneakers-designed-by-virgil-abloh-blue/"
                                            }
                                        >
                                            <div className="m-banner-info-price-wrap">
                                                Price: ₦969751
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="m-banner-buttons">
                                <div className="m-banner-btn">
                                    <a href="/box/surprise-box/">
                                        <img
                                            src={getStartedBtn}
                                            alt="Get Started"
                                        />
                                    </a>
                                </div>
                                <div className="m-banner-btn">
                                    <a href="/#main-how-it-works-block">
                                        <img
                                            src={howItWorksBtn}
                                            alt="How It Works"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        </>
                        }
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default React.memo(BannerView);
