import {combineReducers, createStore} from 'redux';

import ui  from './reducers/ui.js';
import app  from './reducers/app.js';
import box  from './reducers/box.js';
import user  from './reducers/user.js';
import prize from "./reducers/prize.js";
import product  from './reducers/product.js';
import error  from './reducers/error.js';
import notification from './reducers/notification.js'

const reducer = combineReducers({
    app,
    ui,
    user,
    box,
    product,
    prize,
    error,
    notification
});

const store = createStore(reducer);

export default store;